import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import axios from 'axios'
import { createI18n } from 'vue-i18n'
import yaml from 'js-yaml'
import Vue from "vue";
import '../node_modules/flowbite-vue/dist/index.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(fab)
library.add(faPhone) // new line
library.add(fas)

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$axios = axios

Promise.all([
  fetch('/translations.yml').then(response => response.text()),
])
.then(([translationsData, countriesData]) => {
  const translations = yaml.load(translationsData);

  const i18n = createI18n({
    locale: 'fr',
    messages: translations
  });

  app.use(i18n);
  app.use(store).use(router).mount('#app');
});