<template>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="966.7835936916703" height="966.7835936916703" viewBox="0 0 966.7835936916703 966.7835936916703">
    <g transform="scale(8.108108108108109) translate(10, 10)">
      <defs id="SvgjsDefs14450"/>
      <g id="SvgjsG14451" featureKey="symbolFeature-0" transform="matrix(1.272264532248264,0,0,1.272264532248264,-14.249362518515355,-13.994909854730903)" fill=#fff>
        
        <path d="M29.5,25.5c2.5,0,4.6-2.1,4.6-4.6s-2.1-4.6-4.6-4.6c-2.5,0-4.6,2.1-4.6,4.6S27,25.5,29.5,25.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-30; -10,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M27,58c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C27.6,56.7,27,57.3,27,58z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,10; 15,-15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M36.2,31.3c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C34.3,30.4,35.1,31.3,36.2,31.3z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 30,20; -25,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M21.1,48.6c2.5,0,4.6-2.1,4.6-4.6c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6C16.6,46.5,18.6,48.6,21.1,48.6z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -15,-20; 25,30; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M14.5,37.8c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C12.6,37,13.4,37.8,14.5,37.8z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-25; -20,15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M13.8,42c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3C13.2,43.3,13.8,42.7,13.8,42z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,10; 20,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M19.9,31.2c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2S18.1,31.2,19.9,31.2z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 15,5; -15,-15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M58.8,13.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C57.5,13,58.1,13.6,58.8,13.6z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,-20; 30,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M23.8,57.5c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6C21.3,63.1,23.8,60.6,23.8,57.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,20; -10,-30; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M79.9,33.9c2.5,0,4.6-2.1,4.6-4.6s-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6S77.3,33.9,79.9,33.9z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -30,30; 10,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M69.6,65.5c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2C71,62.3,69.6,63.7,69.6,65.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-20; -10,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M72.8,22.9c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2C69.6,21.5,71,22.9,72.8,22.9z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,20; 20,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M65.7,31.2c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2S63.9,31.2,65.7,31.2z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 15,-10; -15,15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M77.2,42.5c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6s-2.5-5.6-5.6-5.6C79.7,36.9,77.2,39.4,77.2,42.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,30; 20,-30; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M74,42c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3C73.4,43.3,74,42.7,74,42z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-15; -10,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M64.8,16.1c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9S63.8,16.1,64.8,16.1z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,10; 20,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M70.8,37.8c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9s-1.9,0.9-1.9,1.9C68.9,37,69.8,37.8,70.8,37.8z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-10; -20,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M31.4,34.5c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2C30,37.7,31.4,36.3,31.4,34.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -15,10; 15,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M64.8,68.7c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9C66.7,69.6,65.9,68.7,64.8,68.7z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-20; -10,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M58.3,76.4c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S61.4,76.4,58.3,76.4z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-10; -20,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M71.5,74.5c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6S74,74.5,71.5,74.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,20; 20,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M57.5,72.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C58.1,71.3,57.5,71.8,57.5,72.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 15,-10; -15,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M88.5,56.7c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S89.1,56.7,88.5,56.7z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,10; 20,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M86.5,62.2c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9C88.4,63,87.6,62.2,86.5,62.2z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-20; -10,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M56.2,25.5c2.5,0,4.6-2.1,4.6-4.6s-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6S53.7,25.5,56.2,25.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,10; 10,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M79.9,51.4c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6C84.4,53.5,82.4,51.4,79.9,51.4z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-20; -20,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M21.1,66.1c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S23.7,66.1,21.1,66.1z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,10; 20,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M81.1,68.8c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2S82.9,68.8,81.1,68.8z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-10; -10,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M30.2,62.2c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9C32.1,63,31.2,62.2,30.2,62.2z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,20; 10,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M35.3,68.8c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2S37.1,68.8,35.3,68.8z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 15,-15; -15,15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M36.2,83.9c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9S37.2,83.9,36.2,83.9z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -20,20; 20,-20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M28.2,77.1c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2C31.4,78.5,30,77.1,28.2,77.1z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-20; -20,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M41,27.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C41.5,26.2,41,26.8,41,27.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -15,15; 15,-15; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M48.3,17.9c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6C45.8,23.6,48.3,21.1,48.3,17.9z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 10,-10; -10,10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M42.2,86.4c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3C43.5,87,42.9,86.4,42.2,86.4z">
          <animateTransform attributeName="transform" type="translate" values="0,0; 20,-20; -20,20; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
  
        <path d="M44.8,74.5c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S47.3,74.5,44.8,74.5z">
          <animateTransform attributeName="transform" type="translate" values="0,0; -10,10; 10,-10; 0,0" dur="3s" repeatCount="indefinite"/>
        </path>
        
      </g>
    </g>
  </svg>
  
  
  </template>
  
  <script>
  export default {}
  </script>