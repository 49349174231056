<template>
  <a class="otp-sign-in-button flex items-center" @click="$emit('toggleOTPMode')">
    <img src="/img/icons/black_logo.png" alt="Logo" class="w-5 h-5 mr-3" />
    One-time password
  </a>
</template>

<script>
export default {
  name: "OtpSignInButton",
};
</script>

<style scoped>
.otp-sign-in-button {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 10px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.otp-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.otp-sign-in-button:active {
  background-color: #eeeeee;
}

.otp-sign-in-button:active {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.otp-sign-in-button:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
</style>
