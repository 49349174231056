import axios from "axios";
import jsyaml from "js-yaml";
import apiClient from './apiClient';

export default {
  async show(user_id, platform_integration_id) {
    try {
      const response = await apiClient.get(
        `api/v1/platform_users/users/${user_id}/integration_accesses/${platform_integration_id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const createResponse = await apiClient.post(
        `api/v1/platform_users/integration_accesses`,
        { integration_access: translatedFormData }
      );

      return createResponse;
    } catch (error) {
      throw error;
    }
  },

  async delete(user_id, platform_integration_id) {
    try {
      const response = await apiClient.delete(
        `api/v1/platform_users/users/${user_id}/integration_accesses/${platform_integration_id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
