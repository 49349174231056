<template>
  <nav aria-label="Page navigation example" class="flex justify-center items-center">
    <ul class="flex items-center -space-x-px h-10 text-base">
      <li>
        <button
          @click="$emit('prevPage')"
          :disabled="currentPage === 1"
          class="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-tenth-color bg-first-color border border-e-0 border-tenth-color rounded-s-lg hover:bg-first-color hover:text-tenth-color dark:bg-first-color dark:border-tenth-color dark:text-tenth-color dark:hover:bg-first-color dark:hover:text-fifth-color"
        >
          <span class="sr-only">Previous</span>
          <svg
            class="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>
      </li>
      <li v-for="page in pagesToDisplay" :key="page">
        <button
          v-if="typeof page === 'number'"
          @click="$emit('goToPage', page)"
          :class="{
            'flex items-center justify-center px-4 h-10 text-first-color border border-tenth-color bg-tenth-color hover:bg-eighth-color hover:text-first-color dark:border-tenth-color dark:bg-tenth-color dark:text-first-color':
              currentPage === page,
            'flex items-center justify-center px-4 h-10 leading-tight text-tenth-color bg-first-color border border-tenth-color hover:bg-third-color hover:text-first-color dark:bg-first-color dark:border-tenth-color dark:text-tenth-color dark:hover:bg-third-color dark:hover:text-first-color':
              currentPage !== page,
          }"
        >
          {{ page }}
        </button>
        <span 
          v-else 
          class="flex items-center justify-center px-4 h-10 leading-tight text-tenth-color bg-first-color border border-tenth-color hover:bg-third-color hover:text-first-color dark:bg-first-color dark:border-tenth-color dark:text-tenth-color dark:hover:bg-third-color dark:hover:text-first-color"
        >
          {{ page }}
        </span>
      </li>
      <li>
        <button
          @click="$emit('nextPage')"
          :disabled="currentPage === totalPages"
          class="flex items-center justify-center px-4 h-10 leading-tight text-tenth-color bg-first-color border border-tenth-color rounded-e-lg hover:bg-first-color hover:text-tenth-color dark:bg-first-color dark:border-tenth-color dark:text-tenth-color dark:hover:bg-first-color dark:hover:text-fifth-color"
        >
          <span class="sr-only">Next</span>
          <svg
            class="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pagesToDisplay() {
      if (this.totalPages <= 15) {
        // If totalPages is 15 or less, return an array of all pages
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
      } else {
        // If totalPages is more than 15, return a range with ellipsis
        const delta = 2;
        const range = [];
        range.push(1);
        if (this.currentPage > delta + 2) {
          range.push('...');
        }
        for (let i = Math.max(2, this.currentPage - delta); i <= Math.min(this.totalPages - 1, this.currentPage + delta); i++) {
          range.push(i);
        }
        if (this.currentPage < this.totalPages - delta - 1) {
          range.push('...');
        }
        if (this.totalPages > 1) {
          range.push(this.totalPages);
        }
        return range;
      }
    },
  },
};
</script>