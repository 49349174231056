import axios from 'axios';
import router from '../router';
import authService from '../services/users/token';

const API_URL = process.env.VUE_APP_API_URL;

const apiClient = axios.create({
  baseURL: API_URL,
  headers: authService.getTokens(),
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response?.data?.errors?.[0] === 'Unsubscripted') {
      router.push('/pricing');
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiClient;