<template>
  <sidebar-component></sidebar-component>
  <div class="pr-4 pl-4 pb-4 sm:ml-64 mt-10">
    <div class="flex space-x-8">
      <div class="w-1/3 pl-8">
        <form
          @submit.prevent="handleSubmit"
          class="relative overflow-x-auto shadow-md sm:rounded-lg border border-e-0 border-first-color bg-first-color p-4 space-y-4"
        >
          <div class="flex items-center justify-between bg-first-color border-b border-fourth-color pb-4">
            <span class="font-bold text-tenth-color">User informations</span>
            <DeleteModal
              v-if="showModal"
              @confirm="deleteUser"
              @cancel="showModal = false"
            />
          </div>
          <div class="mx-auto"></div>
          <div v-for="(value, key, index) in user" :key="key" class="space-y-2">
            <div v-if="index % 2 === 0" class="flex space-x-4">
              <div class="flex-1 relative">
                <label
                  :for="key"
                  class="text-left block text-sm font-medium leading-6 text-tenth-color"
                >
                  {{ $t(key) }}
                </label>
                <div class="relative">
                  <input
                    v-model="user[key]"
                    :id="key"
                    :disabled="!isEditing"
                    type="text"
                    :class="['border border-tenth-color text-tenth-color text-sm rounded-lg focus:ring-tenth-color focus:border-tenth-color block w-full  p-2.5', isEditing ? 'bg-first-color' : 'bg-second-color']"
                    class="dark:border-tenth-color dark:placeholder-fourth-color dark:text-tenth-color dark:focus:ring-tenth-color dark:focus:border-tenth-color"
                  />
                </div>
              </div>
              <div
                v-if="index + 1 < Object.keys(user).length"
                class="flex-1 relative"
              >
                <label
                  :for="Object.keys(user)[index + 1]"
                  class="text-left block text-sm font-medium leading-6 text-tenth-color"
                >
                  {{ $t(Object.keys(user)[index + 1]) }}
                </label>
                <div class="relative">
                  <input
                    v-model="user[Object.keys(user)[index + 1]]"
                    :id="Object.keys(user)[index + 1]"
                    :disabled="!isEditing"
                    type="text"
                    :class="['border border-tenth-color text-tenth-color text-sm rounded-lg focus:ring-tenth-color focus:border-tenth-color block w-full  p-2.5', isEditing ? 'bg-first-color' : 'bg-second-color']"
                    class="dark:border-tenth-color dark:placeholder-fourth-color dark:text-tenth-color dark:focus:ring-tenth-color dark:focus:border-tenth-color"
                  />
                </div>
              </div>
            </div>
            <div v-else class="hidden"></div>
          </div>
          <div class="flex justify-end space-x-2">
            <button @click="toggleEdit" type="button" class="btn">{{ isEditing ? 'Validate' : 'Edit' }}</button>
            <button
              @click="confirmDeleteUser"
              type="button"
              class="btn"
            >
              <font-awesome-icon
                icon="fa-solid fa-trash"
                size="lg"
                class="text-red-color hover:text-red-color-hover"
              />
            </button>
          </div>
        </form>
      </div>
      <div class="w-2/3">
        <PlatformUserIntergrationTable v-if="user" :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/services/user";
import DeleteModal from "../components//util/DeleteModal.vue";
import PlatformUserIntergrationTable from "../components/platform-integration/PlatformUserIntergrationTable.vue";
import SidebarComponent from "@/components/util/SidebarComponent.vue";

export default {
  components: {
    DeleteModal,
    PlatformUserIntergrationTable,
    SidebarComponent,
  },
  data() {
    return {
      user: null,
      isEditing: false,
      showModal: false,
    };
  },
  async created() {
    try {
      const id = this.$route.params.id;
      const response = (await user.show(id)).data;
      this.user = response.data.attributes;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    confirmDeleteUser() {
      this.showModal = true;
    },
    toggleEdit() {
      if (this.isEditing) {
        this.updateUser();
      }
      this.isEditing = !this.isEditing;
    },
    async updateUser() {
      try {
        await user.update(this.$route.params.id, this.user);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteUser() {
      try {
        this.showModal = false;
        await user.delete(this.$route.params.id);
        this.$router.push({ name: "users" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
