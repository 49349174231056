<template>
  <h2 class="text-3xl font-extrabold text-gray-900">Create your account</h2>
  <div class="mt-1 font-extrabold text-sm" id="user_avatar_help">
    Already have an account?
    <router-link
      :to="{ path: '/signin', query: this.$route.query }"
      style="color: #1c64f2; text-decoration: underline"
      >Sign in</router-link
    >
  </div>
  <div class="mt-5">
    <GoogleSignInButton />
  </div>
  <form @submit.prevent="submitForm" class="mx-auto mt-16 max-w-xl sm:mt-10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
      <div>
        <label
          for="first-name"
          class="block text-sm font-semibold leading-6 text-gray-900"
          >First name</label
        >
        <div class="mt-2.5">
          <input
            v-model="firstName"
            type="text"
            name="first-name"
            id="first-name"
            autocomplete="given-name"
            required
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div>
        <label
          for="last-name"
          class="block text-sm font-semibold leading-6 text-gray-900"
          >Last name</label
        >
        <div class="mt-2.5">
          <input
            v-model="lastName"
            type="text"
            name="last-name"
            id="last-name"
            autocomplete="family-name"
            required
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          :class="{ 'text-red-700': emailError }"
          for="email"
          class="block text-sm font-semibold leading-6 text-gray-900"
          >Email</label
        >
        <div class="mt-2.5">
          <input
            v-model="email"
            :class="{ 'bg-red-50 border-red-500 text-red-900': emailError }"
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            required
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <p v-if="emailError" class="mt-2 text-sm text-red-600">
          <span class="font-medium">Oops!</span> {{ emailError }}
        </p>
      </div>
      <div class="sm:col-span-2">
        <label
          for="password"
          class="block text-sm font-semibold leading-6 text-gray-900"
          >Password</label
        >
        <input
          v-model="password"
          type="password"
          name="password"
          id="password"
          autocomplete="password"
          required
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div class="sm:col-span-2">
        <label
          for="confirm-password"
          class="block text-sm font-semibold leading-6 text-gray-900"
          >Confirm your password</label
        >
        <input
          v-model="confirmPassword"
          type="password"
          name="confirm-password"
          id="confirm-password"
          autocomplete="new-password"
          required
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <p v-if="passwordError" class="text-red-500 text-xs mt-2">
          Passwords do not match.
        </p>
      </div>
    </div>
    <div class="mt-10">
      <button
        type="submit"
        class="block w-full rounded-md bg-[#1C64F2] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#1C64F2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Sign up
      </button>
      <div
        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
        id="user_avatar_help"
      ></div>
    </div>
  </form>
</template>

<script>
import auth from "@/services/users/auth";
import token from "@/services/users/token";
import stripe from "@/services/stripe";
import GoogleSignInButton from "@/components/auth/GoogleSignInButton.vue";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      passwordError: false,
      emailError: "",
    };
  },
  computed: {
    isPasswordConfirmed() {
      return this.password === this.confirmPassword;
    },
  },
  methods: {
    async submitForm() {
      if (!this.validateForm()) return;
      const formData = this.getFormData();

      try {
        const response = await this.registerUser(formData);
        this.handleSuccess(response);
      } catch (error) {
        this.handleError(error);
      }
    },
    validateForm() {
      if (!this.isPasswordConfirmed) {
        this.passwordError = true;
        return false;
      }
      this.passwordError = false;
      return true;
    },
    getFormData() {
      return auth.payload(this.$data);
    },
    async registerUser(formData) {
      return await auth.signUp(formData);
    },
    async handleSuccess(response) {
      token.setTokens(response);
      if (this.$route.query.plan) {
        await this.handleStripeCheckout();
      } else {
        this.$router.push("/users");
      }
    },
    async handleStripeCheckout() {
      const stripeResponse = await stripe.checkout({
        planId: this.$route.query.plan,
      });
      window.location.href = stripeResponse.data.url;
    },
    handleError(error) {
      if (error.response && error.response.status === 422) {
        this.emailError = "Email already taken!";
        this.passwordError = false;
      } else {
        console.error(error);
      }
    },
  },
};
</script>
