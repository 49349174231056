<template>
  <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
    <div v-if="notSubscribedMessage" class="text-red-500 font-bold text-left mb-4">
      {{ notSubscribedMessage }}
    </div>
    <div class="flex justify-between">
      <div>
        <h5
          class="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2"
        >
          <span v-if="isLoading" class="flex items-center justify-center">
            <svg
              aria-hidden="true"
              class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </span>
          <span v-else>{{ this.series[0].totalCount }}</span>
        </h5>
        <p class="text-lg font-bold text-blue-600 dark:text-blue-400">
          {{ activeUsersText }}
        </p>
        <span
          class="text-sm font-bold text-gray-500 dark:text-gray-400 lowercase"
          >in {{ selectedDays.toLowerCase() }}</span
        >
      </div>

      <div class="flex items-baseline text-gray-900 dark:text-white">
        <span v-if="isLoading" class="text-5xl font-extrabold tracking-tight">
          <svg
            aria-hidden="true"
            class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </span>
        <span v-else class="flex flex-col items-baseline">
          <span class="flex items-baseline">
            <span class="text-3xl font-semibold">€</span>
            <span class="text-5xl font-extrabold tracking-tight">{{
              amountToPay
            }}</span>
            <span class="ml-1 text-xl font-bold">/month</span>
          </span>
          <span
            class="text-sm font-bold text-gray-500 dark:text-gray-400 lowercase"
            >for current subscription</span
          >
        </span>
      </div>
    </div>

    <apexchart
      :key="chartKey"
      ref="apexchart"
      type="area"
      height="200"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>

    <div
      class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between"
    >
      <div class="flex justify-between items-center pt-5 relative">
        <button
          id="dropdownDefaultButton"
          @click="toggleDropdown"
          class="text-sm font-bold text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
          type="button"
        >
          {{ selectedDays }}
          <svg
            class="w-2.5 m-2.5 ms-1.5"
            :class="{ 'rotate-180': isDropdownVisible }"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          v-if="isDropdownVisible"
          id="lastDaysdropdown"
          class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute bottom-full mb-2"
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <a
                href="#"
                @click.prevent="getLastSevenDays"
                class="block px-4 py-2 font-bold hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Last 7 days</a
              >
            </li>
            <li>
              <a
                href="#"
                @click.prevent="getLastThirtyDays"
                class="block px-4 py-2 font-bold hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Last 30 days</a
              >
            </li>
            <li>
              <a
                href="#"
                @click.prevent="getLastNinetyDays"
                class="block px-4 py-2 font-bold hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Last 90 days</a
              >
            </li>
            <li>
              <a
                href="#"
                @click.prevent="getCurrentSubscriptionMonth"
                class="block px-4 py-2 font-bold hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Current Subscription</a
              >
            </li>
          </ul>
        </div>
        <a
          href="#"
          @click.prevent="toggleActiveUserInfo"
          class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
        >
          What is an active user?
          <svg
            class="w-2.5 h-2.5 ms-1.5"
            :class="{ 'rotate-90': showActiveUserInfo }"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="m1 1 4 4-4 4"
            />
          </svg>
        </a>
      </div>
      <div
        v-if="showActiveUserInfo"
        class="pt-2 text-gray-600 dark:text-gray-400"
      >
        An active user is defined as a unique user who is present in at least
        one of your connected integrations. Regardless of whether the same user
        is present in multiple integrations, they are counted as a single active
        user to avoid duplication. If a user is deleted from all integrations,
        they are no longer considered an active user and will not be included in
        the billing. However, if a user exists at any point during your monthly
        billing period, they will be counted as an active user for that entire
        period. This ensures that you are only billed for users who have
        actually interacted with your system within the billing cycle.
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import usage from "@/services/usage";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["subscriptionEndDate", "subscriptionStartDate"],
  async created() {
    try {
      this.isLoading = true;
      await this.fetchData(6);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    activeUsersText() {
      return this.series[0].totalCount === 1 ? "Active user" : "Active users";
    },
  },
  methods: {
    toggleActiveUserInfo() {
      this.showActiveUserInfo = !this.showActiveUserInfo;
    },
    getLastDays(days, fromDate = new Date()) {
      const dates = [...Array(days)].map((_, i) => {
        const d = new Date(fromDate);
        d.setDate(d.getDate() - i);
        return d.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      });
      return dates.reverse();
    },
    getSubscriptionDays() {
      const startDate = new Date(this.subscriptionStartDate);
      const endDate = new Date(this.subscriptionEndDate);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    },
    getCurrentSubscriptionMonth() {
      const subscriptionDays = this.getSubscriptionDays();
      this.chartOptions.xaxis.categories = this.getLastDays(
        subscriptionDays,
        this.subscriptionEndDate
      );
      this.chartKey++;
      this.fetchData("current_subscription");
      this.selectedDays = "Current Subscription";
      this.toggleDropdown();
    },
    getLastSevenDays() {
      this.chartOptions.xaxis.categories = this.getLastDays(7);
      this.chartKey++;
      this.fetchData(6);
      this.selectedDays = "Last 7 days";
      this.toggleDropdown();
    },
    getLastThirtyDays() {
      this.chartOptions.xaxis.categories = this.getLastDays(30);
      this.chartKey++;
      this.fetchData(29);
      this.selectedDays = "Last 30 days";
      this.toggleDropdown();
    },
    getLastNinetyDays() {
      this.chartOptions.xaxis.categories = this.getLastDays(90);
      this.chartKey++;
      this.fetchData(89);
      this.selectedDays = "Last 90 days";
      this.toggleDropdown();
    },
    async fetchData(days) {
      try {
        const response = await usage.index(days);
        this.series[0].data = response.data.series;
        this.series[0].totalCount = response.data.count;
        this.amountToPay = response.data.amount;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Show a message if the user is not subscribed
          this.series[0].totalCount = 0;
          this.amountToPay = 0;
          this.notSubscribedMessage =
            "You are not subscribed. Please subscribe to access usage data.";
        } else {
          console.error(error);
        }
      }
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
  },
  data() {
    return {
      chartKey: 0,
      isDropdownVisible: false,
      selectedDays: "Last 7 days",
      amountToPay: 0,
      isLoading: false,
      showActiveUserInfo: false,
      series: [
        {
          name: "New users",
          data: [],
          totalCount: 0,
        },
      ],
      notSubscribedMessage: "", // Add this to show a message when not subscribed
      chartOptions: {
        chart: {
          type: "area",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        stroke: {
          width: 2,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.getLastDays(7),
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
};
</script>
<style scoped>
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.2s;
}
</style>
