<template>
  <div class="relative pt-10">
    <div class="pb-4 bg-first-color dark:bg-first-color flex items-center justify-between">
      <div v-if="dataType !== 'payment'" class="relative mt-1">
        <label for="table-search" class="sr-only">Search</label>
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-4 h-4 text-first-color0 dark:text-eighth-color" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input type="text" id="table-search" v-model="searchQuery" @input="onSearchInput" class="search-bar" placeholder="Search for items">
      </div>
      <button
        v-if="dataType !== 'integration' && dataType !== 'platformIntegration' && dataType !== 'payment'"
        @click="showModal = true"
        class="text-first-color hover:bg-tenth-color focus:ring-4 focus:outline-none focus:ring-eighth-color font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-eighth-color dark:hover:bg-tenth-color dark:focus:ring-tenth-color"
        type="button"
        :style="{ backgroundColor: 'tenth-color' }"
      >
        Create
      </button>
    </div>
    <div v-if="showModal">
      <div class="fixed inset-0 bg-black bg-opacity-50 z-40 backdrop-blur-md"></div>
      <component
        :is="`${capitalizedDataType}CreateForm`"
        @itemCreated="fetchData"
        @close="showModal = false"
      />
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg" :class="{ 'p-6': dataType === 'payment' }">
      <div v-if="dataType === 'payment'" class="flex items-center mb-4">
        <h2 class="font-weight-700 color-050505 text-xl font-semibold">Billing history</h2>
      </div>
      <table class="w-full text-sm text-left rtl:text-right text-first-color0 dark:text-gray-400">
        <thead class="text-xs text-tenth-color uppercase bg-first-color dark:bg-tenth-color dark:text-gray-400">
          <tr>
            <th v-if="dataType === 'integration' || dataType === 'platformIntegration'" scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
            <th scope="col" class="px-6 py-3" v-for="key in attributeKeys" :key="key">
              {{ $t(key) }}
            </th>
            <th v-if="dataType === 'integration' || dataType === 'platformIntegration'" scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
            <th v-if="dataType === 'platformIntegration'" scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
            <th v-if="dataType === 'platformIntegration'" scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
            class="odd:bg-first-color odd:dark:bg-tenth-color even:bg-first-color even:dark:bg-gray-800 border-b dark:border-tenth-color"
          >
            <td v-if="dataType === 'integration' || dataType === 'platformIntegration'" class="px-6 py-4">
              <div class="flex items-center justify-center">
                <font-awesome-icon
                  class="fa-3x"
                  icon="fa-brands fa-hubspot"
                  v-if="item.attributes.name === 'Hubspot'"
                  style="color: #fd5d35"
                />
                <img
                  v-if="item.attributes.name === 'Slack'"
                  class="h-10 w-10"
                  src="https://previews.us-east-1.widencdn.net/preview/48045879/assets/asset-view/4fe2aea7-92aa-4833-94af-97b882d8cb2f/thumbnail/eyJ3IjoyMDQ4LCJoIjoyMDQ4LCJzY29wZSI6ImFwcCJ9?Expires=1719403200&Signature=thuLJHtA2oJt6pCnwacVJfJHGELBEG4zlQj-gz3TMfbu21KmrcoQMYq5rjSTpkUPBYdZpiakeNXtMAAgG7M5WB3ggpIwaO4fEt0F2avHqby69KhM~8bmRfeue67OZKM~I9yZcmBTZz1hUl3fYt8PAt39P-BSXYt-aOD~iaYP7KiDqs~C7x3G31Oe9JnNoY7R3HeAgISYefT92nHvrxgXnxJ~bvRWxvYqNkyu28jxebFFe1TT0jXdgV5b5TCDosSXjOzw2-9KPV6l5Hki7~3kbOoUFmv2ErN5l3Anue1JVwRKrv-CP4nbPzLxYR6tn8h0wL1jLF2Nk37LcPhnFSAejQ__&Key-Pair-Id=APKAJM7FVRD2EPOYUXBQ"
                  alt="Slack Logo"
                />
                <font-awesome-icon
                  class="fa-3x"
                  icon="fa-brands fa-github"
                  v-if="item.attributes.name === 'Github'"
                  style="color: #02040a"
                />
                <img
                  v-if="item.attributes.name === 'Google'"
                  class="supercloud-logo"
                  alt="Logo Google Cloud"
                  src="https://www.gstatic.com/pantheon/images/welcome/supercloud.svg"
                />
                <svg
                  v-if="item.attributes.name === 'Ringover'"
                  class="h-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M12.7487636,26.0357143 C6.44286601,26.0357143 1.49207336,21.8112333 0.373650926,15.6875264 C0.359732676,15.6113197 0.34019606,15.4963529 0.315041079,15.342626 L6.56344993,15.3427385 C6.5908412,15.4348227 6.61183136,15.5034988 6.62642038,15.5487668 C7.55282415,18.4232847 10.0690497,19.9096639 12.7487636,19.9096639 C15.432939,19.9096639 17.9530813,18.4494006 18.8757208,15.5535215 C18.8904769,15.5072066 18.9116851,15.4369081 18.9393453,15.342626 L25.1824861,15.342626 C25.1615897,15.4711433 25.1454487,15.5673025 25.1340631,15.6311037 C24.0358226,21.7852691 19.0740099,26.0357143 12.7487636,26.0357143 Z M12.7487636,0 C19.3444301,0 24.4575906,4.57963363 25.2535401,11.1938247 C25.264946,11.2886056 25.2800508,11.4317085 25.2988544,11.6231335 L19.142136,11.6231335 C19.1243944,11.5144466 19.1105316,11.4333869 19.1005476,11.3799545 C18.4481121,7.88825231 15.697413,6.12605042 12.7487636,6.12605042 C9.83383355,6.12605042 7.11235238,7.84817927 6.42018275,11.2608445 C6.40413025,11.3399896 6.38253305,11.4607526 6.35539115,11.6231335 L0.198672699,11.6231335 C0.225874504,11.3543914 0.2482484,11.153762 0.265794389,11.0212454 C1.12923201,4.50010485 6.21061717,0 12.7487636,0 Z"
                    fill="#00BD82"
                  ></path>
                </svg>
                <svg
                  v-if="item.attributes.name === 'Aircall'"
                  class="h-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 40 38"
                >
                  <path
                    d="M30.07.86C27.82.34 24.123 0 19.943 0c-4.18 0-7.878.34-10.127.86C5.33 1.804 1.799 5.346.858 9.844.338 12.1 0 15.808 0 20s.339 7.9.858 10.156c.941 4.498 4.473 8.04 8.958 8.984 2.25.52 5.947.86 10.127.86 4.18 0 7.878-.34 10.127-.86 4.485-.944 8.017-4.486 8.958-8.984.52-2.256.858-5.964.858-10.156s-.339-7.9-.858-10.156c-.941-4.498-4.473-8.04-8.958-8.984z"
                    fill="#00BD82"
                  ></path>
                  <path
                    d="M24.735 30.705a3.023 3.023 0 00-2.16-1.79c-.584-.135-1.546-.223-2.632-.223-1.087 0-2.049.088-2.633.224-.97.204-1.77.876-2.151 1.77-.133.31-.45.499-.786.468a28.05 28.05 0 01-.215-.02c-1.564-.157-2.793-.375-3.507-.629a1.27 1.27 0 01-.816-1.354c.121-1.632 1.11-5.454 2.586-9.816 1.688-4.987 3.454-9.024 4.25-9.811a1.71 1.71 0 01.681-.436c.523-.177 1.487-.296 2.59-.296 1.104 0 2.068.119 2.59.296l.009.003a1.711 1.711 0 01.674.433c.796.787 2.561 4.824 4.249 9.81 1.477 4.363 2.465 8.185 2.587 9.817 0 .013 0 .027.002.041l.002.015a6.196 6.196 0 01.004.108 1.27 1.27 0 01-.824 1.19c-.714.254-1.942.472-3.504.628l-.246.023a.74.74 0 01-.75-.45z"
                    fill="#fff"
                  ></path>
                </svg>
              </div>
            </td>
            <td class="px-6 py-4 description-column" v-for="key in attributeKeys" :key="key">
              <router-link v-if="dataType === 'user'" class="hover:underline bold-text" :to="{ name: dataType, params: { id: item.id } }">
                <span v-if="typeof item.attributes[key] === 'object'">{{ displayObject(item.attributes[key]) }}</span>
                <span v-else-if="key !== 'connected'">{{ item.attributes[key] }}</span>
                <div v-else>
                  <div v-if="item.attributes[key]" class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                    <span>Online</span>
                  </div>
                  <div v-else class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>
                    <span>Offline</span>
                  </div>
                </div>
              </router-link>
              <span v-else class="bold-text">
                <span v-if="typeof item.attributes[key] === 'object'">{{ displayObject(item.attributes[key]) }}</span>
                <span v-else-if="key !== 'connected'">{{ item.attributes[key] }}</span>
                <div v-else>
                  <div v-if="item.attributes[key]" class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                    <span>Online</span>
                  </div>
                  <div v-else class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>
                    <span>Offline</span>
                  </div>
                </div>
              </span>
            </td>
            <td v-if="dataType === 'platformIntegration'" class="no-text-trim">
              <button
                class="p-2 inline-flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg relative"
                @mouseover="showTooltip(item.id)"
                @mouseleave="hideTooltip(item.id)"
              >
                <font-awesome-icon icon="fa-solid fa-circle-info" bounce size="lg" style="color: #ffb42e" />
                <div
                  v-if="tooltipVisible[item.id]"
                  class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-first-color text-xs rounded shadow-lg w-60 z-10"
                >
                  {{ getTooltipText(item.attributes.name) }}
                </div>
              </button>
            </td>
            <td v-if="dataType === 'platformIntegration'">
              <button @click="syncPlatformIntegration(item.id)">
                <font-awesome-icon
                  :spin="syncingIds.includes(item.id)"
                  icon="fa-solid fa-arrows-rotate"
                  style="color: #057fee"
                  v-if="item.isConnected && (item.attributes.name !== 'Hubspot' && item.attributes.name !== 'Google' && item.attributes.name !== 'Slack')"
                />
              </button>
            </td>
            <td v-if="dataType === 'integration'">
              <button class="px-6 py-3 bold-text cursor-pointer" @click="confirmSelect(item.id)" :disabled="item.isSelected" :style="{ color: '#057fee' }">
                {{ item.isSelected ? "Already selected" : "Select" }}
              </button>
            </td>
            <td v-if="dataType === 'platformIntegration'">
              <button class="px-6 py-3 bold-text cursor-pointer" @click="item.isConnected ? confirmDisconnection(item.id) : confirmConnection(item.id)" :style="{ color: '#057fee' }">
                {{ item.isConnected ? "Disconnect" : "Connect" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :current-page="currentPage" :total-pages="totalPages" @goToPage="goToPage" @nextPage="nextPage" @prevPage="prevPage" />
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import user from "@/services/user";
import integration from "@/services/integration";
import platformIntegration from "@/services/platformIntegration";
import oauthConnection from "@/services/oauthConnection";
import payment from "@/services/payment";

import Pagination from "@/components/util/Pagination.vue";

export default {
  components: {
    Pagination,
  },
  props: {
    dataType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      selectedId: null,
      showModal: false,
      showSelectModal: false,
      showConnectModal: false,
      showDisconnectModal: false,
      syncingIds: [],
      tooltipVisible: [],
      currentPage: 1,
      totalPages: 0,
      searchQuery: '',
    };
  },
  computed: {
    attributeKeys() {
      return this.data.length > 0 ? Object.keys(this.data[0].attributes) : [];
    },
    capitalizedDataType() {
      return this.dataType.charAt(0).toUpperCase() + this.dataType.slice(1);
    },
  },
  watch: {
    dataType: {
      handler(newVal) {
        this.fetchData(this.currentPage, this.searchQuery);
      },
      immediate: true,
    },
    searchQuery: {
      handler(newVal) {
        this.fetchData(this.currentPage, newVal);
      },
    },
  },
  methods: {
    onSearchInput() {
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    async syncPlatformIntegration(id) {
      this.syncingIds.push(id);
      const updatePromise = platformIntegration.update(id, { sync: true });
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 4000));
      await Promise.all([updatePromise, timeoutPromise]);
      this.syncingIds = this.syncingIds.filter((syncingId) => syncingId !== id);
    },
    getTooltipText(name) {
      if (name !== "Hubspot" && name !== "Google" && name !== "Slack") {
        return `Occasionally, a user created directly in ${name} may not synchronize with Joiners automatically. To resolve this, simply click the 'Sync' button on the right to initiate synchronization manually`;
      }
      if (name === "Hubspot" || name === "Google" || name === "Slack") {
        return `As you can see that the Sync button is disabled for ${name}. This is because ${name} provides real-time data information regarding users`;
      }
    },
    showTooltip(id) {
      this.tooltipVisible[id] = true;
    },
    hideTooltip(id) {
      this.tooltipVisible[id] = false;
    },
    async checkIntegrationExists(integration_id) {
      try {
        const response = await platformIntegration.show(integration_id);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return false;
        }
        throw error;
      }
    },
    async disconnectIntegration() {
      try {
        this.showDisconnectModal = false;
        await platformIntegration.update(this.selectedId, { connected: false });
        const selectedItem = this.data.find((item) => item.id === this.selectedId);
        if (selectedItem) {
          selectedItem.isConnected = false;
          selectedItem.attributes.connected = false;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    async connectIntegration() {
      try {
        this.showConnectModal = false;
        const response = await oauthConnection.authorizationLink({
          platform_integration: this.selectedId,
        });
        const sanitizedURL = DOMPurify.sanitize(response.data.authorizationLink);
        window.location.href = sanitizedURL;
        const selectedItem = this.data.find((item) => item.id === this.selectedId);
        if (selectedItem) {
          selectedItem.isConnected = true;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    async createIntegration() {
      try {
        this.showSelectModal = false;
        await platformIntegration.create({ integration: this.selectedId });
        this.$router.push({ name: "users" });
        const selectedItem = this.data.find((item) => item.id === this.selectedId);
        if (selectedItem) {
          selectedItem.isSelected = true;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    confirmConnection(id) {
      this.selectedId = id;
      this.showConnectModal = true;
    },
    confirmDisconnection(id) {
      this.selectedId = id;
      this.showDisconnectModal = true;
    },
    confirmSelect(id) {
      this.selectedId = id;
      this.showSelectModal = true;
    },
    async fetchData(page, query = '') {
      try {
        this.data = [];
        this.totalPages = 0;

        const services = {
          integration: integration,
          platformIntegration: platformIntegration,
          user: user,
          payment: payment,
        };

        const service = services[this.dataType];

        if (!service) {
          console.error(`Unsupported data type: ${this.dataType}`);
          return;
        }
        const response = await service.index(query, page);
        const data = response.data.data;
        this.totalPages = response.data.meta.total_pages;

        if (this.dataType === "integration") {
          for (let item of data) {
            item.isSelected = await this.checkIntegrationExists(item.id);
          }
        }

        if (this.dataType === "platformIntegration") {
          for (let item of data) {
            item.isConnected = item.attributes.connected;
          }
        }

        this.data = data;
      } catch (error) {
        console.error(error);
      }
    },
    displayObject(obj) {
      if (obj && typeof obj === "object") {
        const key = Object.keys(obj).find((key) => key !== "id");
        return obj[key];
      }
      return "";
    },
    goToPage(page) {
      this.currentPage = page;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.bold-text {
  font-weight: 500;
  color: #050505;
}

.supercloud-logo {
  width: 50px;
  height: 50px;
}

.font-weight-700 {
  font-weight: 700;
}

.color-050505 {
  color: #050505;
}

.text-2xl {
  font-size: 2em;
  /* adjust as needed */
}

.font-semibold {
  font-weight: 600;
  /* adjust as needed */
}

/* Added styles for table cells to prevent text wrap and manage overflow */
td {
  first-color-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-column {
  max-width: 300px; /* Adjust as needed */
}

/* CSS */
.no-text-trim {
  first-color-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}
</style>
