import jsyaml from 'js-yaml';
import axios from 'axios';
import apiClient from './apiClient';

export default {

async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;
      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const createdResponse = await apiClient.post(
        `api/v1/messages`,
        { message: translatedFormData }
      );
      return createdResponse;
    } catch (error) {
      throw error;
    }
  },
};