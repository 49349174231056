<template>
  <h1 class="ml10">
    <div class="text-wrapper" v-for="(word, index) in line1" :key="index">
      <span class="letter" v-for="(char, charIndex) in word.split('')" :key="charIndex">{{ char }}</span>
    </div>
    <div class="text-wrapper" v-for="(word, index) in line2" :key="index">
      <span class="letter" v-for="(char, charIndex) in word.split('')" :key="charIndex">{{ char }}</span>
    </div>
  </h1>
</template>

<script>
import anime from 'animejs';

export default {
  data() {
    return {
      line1: "Optimize user".split(' '), // Split the text into an array of words
      line2: "integration and transition".split(' ') // Split the text into an array of words
    };
  },
  mounted() {
    // Animation using Vue.js transition hooks
    this.$nextTick(() => {
      this.animateText();
    });
  },
  methods: {
    animateText() {
      anime.timeline({ loop: true })
        .add({
          targets: '.ml10 .letter',
          rotateY: [-90, 0],
          duration: 1300,
          delay: (el, i) => 45 * i
        })
        .add({
          targets: '.ml10',
          opacity: 0,
          duration: 1000,
          easing: "easeOutExpo",
          delay: 1000
        });
    }
  }
};
</script>

<style>
.ml10 {
  position: relative;
  font-weight: 900;
  font-size: 4em;
}

.ml10 .text-wrapper {
  position: relative;
  display: block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml10 .letter {
  display: inline-block;
  line-height: 1em;
  transform-origin: 0 0;
}
</style>