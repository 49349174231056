import apiClient from './apiClient';

export default {
  async index(query = 7) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/user_usages`, {
        params: {
          query: query
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};