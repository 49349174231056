<template>
  <div>
    <ObjectTable :dataType="dataType" class="override-pt" />
  </div>
</template>

<script>
import ObjectTable from "../util/ObjectTable.vue";

export default {
  components: {
    ObjectTable,
  },
  data() {
    return {
      dataType: "payment",
    };
  },
};
</script>

<style scoped>
.override-pt {
  padding-top: 0 !important;
}
</style>
