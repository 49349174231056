<template>
  <h2 class="text-3xl font-extrabold text-gray-900">Connection</h2>
  <div class="mt-2 mb-2 font-extrabold text-sm" id="user_avatar_help">
    Don't have an account?
    <router-link
      :to="{ path: '/signup', query: this.$route.query }"
      style="color: #1c64f2; text-decoration: underline"
    >
      Sign up
    </router-link>
  </div>
  <div class="mt-5">
    <GoogleSignInButton />
  </div>
  <div class="mt-5">
    <OtpSignInButton @toggleOTPMode="toggleOTPMode" />
  </div>
  <form
    @submit.prevent="submitForm"
    class="mx-auto mt-16 max-w-xl sm:mt-10 w-1/2"
  >
    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <label
          :class="{ 'text-red-700': emailError || otpVerifyError }"
          for="email"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{
            !showOTPField
              ? "Enter the code that has been sent to your email"
              : "Email"
          }}
        </label>
        <div class="mt-2.5">
          <input
            v-model="inputValue"
            :placeholder="emailPlaceholder"
            :class="{
              'bg-red-50 border-red-500 text-red-900':
                emailError || otpVerifyError,
            }"
            :type="showOTPField ? 'email' : 'text'"
            name="email"
            id="email"
            autocomplete="email"
            required
            @input="validateEmail"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <p v-if="emailError && showOTPField" class="mt-2 text-sm text-red-600">
          <span class="font-medium">Oops!</span> {{ emailError }}
        </p>
        <p
          v-if="otpVerifyError && !showOTPField"
          class="mt-2 text-sm text-red-600"
        >
          <span class="font-medium">Oops!</span> {{ otpVerifyError }}
        </p>
      </div>

      <div v-if="showPassword" class="sm:col-span-2">
        <label
          for="password"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          Password
        </label>
        <input
          v-model="password"
          type="password"
          name="password"
          id="password"
          autocomplete="password"
          required
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <div class="mt-10">
      <button
        v-if="!showContinueButton"
        type="submit"
        class="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Log in
      </button>
      <button
        v-else
        :disabled="
          (!isEmailValid && showOTPField) || (!otpCode && !showOTPField)
        "
        type="submit"
        class="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {{ buttonText }}
      </button>
    </div>
  </form>
</template>

<script>
import OtpSignInButton from "@/components/auth/OtpSignInButton.vue";
import GoogleSignInButton from "@/components/auth/GoogleSignInButton.vue";
import auth from "@/services/users/auth";
import token from "@/services/users/token";
import stripe from "@/services/stripe";  // Correct import path
import otp from "@/services/otp";        // Correct import path
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    OtpSignInButton,
    GoogleSignInButton,
  },
  data() {
    return {
      email: "",
      emailCopy: "",
      otpCode: "",
      password: "",
      emailError: "",
      otpVerifyError: "",
      showPassword: true,
      showContinueButton: false,
      isEmailValid: false,
      showOTPField: true,
      emailPlaceholder: "Enter your email",
      buttonText: "Continue",
    };
  },
  computed: {
    googleSignInUrl() {
      return `${process.env.VUE_APP_API_URL}auth/google_devise`;
    },
    inputValue: {
      get() {
        return this.showOTPField ? this.email : this.otpCode;
      },
      set(value) {
        if (this.showOTPField) {
          this.email = value;
        } else {
          this.otpCode = value;
        }
      },
    },
  },
  methods: {
    toggleOTPMode() {
      this.showPassword = false;
      this.showContinueButton = true;
    },
    validateEmail() {
      if (this.showOTPField) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isEmailValid = emailPattern.test(this.email);
        if (!this.isEmailValid && this.email !== "") {
          this.emailError = "The E-mail must be a valid email address.";
        } else {
          this.emailError = "";
        }
      }
    },
    async submitForm() {
      if (this.showContinueButton && this.showOTPField) {
        await this.handleSendOTP();
      } else if (!this.showOTPField) {
        await this.handleVerifyOTP();
      } else {
        await this.handleRegularLogin();
      }
    },
    async handleSendOTP() {
      try {
        const response = await otp.send_otp({ email: this.email });
        this.showOTPField = false;
        this.emailPlaceholder = "Enter the code that has been sent to your email";
        this.emailCopy = this.email;
        this.email = "";
        this.buttonText = "Verify code";
      } catch (error) {
        this.handleError(error, "emailError", "This email does not exist. Please create an account");
      }
    },
    async handleVerifyOTP() {
      try {
        const response = await otp.verify_otp({
          otpToken: this.otpCode,
          email: this.emailCopy,
        });
        const payload = token.constructPayload(jwtDecode(response.data.data));
        token.setTokens(payload);
        window.location.href = "/users";
      } catch (error) {
        this.handleError(error, "otpVerifyError", "The code is incorrect or expired.");
      }
    },
    async handleRegularLogin() {
      try {
        const response = await auth.signIn(auth.payload(this.$data));
        token.setTokens(response);
        this.$emit("signInSuccess");
        await this.handleRedirectAfterLogin();
      } catch (error) {
        this.handleError(error, "emailError", "Compte n'existe pas");
      }
    },
    async handleRedirectAfterLogin() {
      if (this.$route.query.plan) {
        const stripeResponse = await stripe.checkout({
          planId: this.$route.query.plan,
        });
        window.location.href = stripeResponse.data.url;
      } else {
        this.$router.push({ path: "/users" });
      }
    },
    handleError(error, errorField, defaultMessage) {
      if (error.response && error.response.status === 404) {
        this[errorField] = defaultMessage;
      } else {
        this[errorField] = "An error occurred. Please try again.";
      }
    },
  },
};
</script>
