<template>
  <sidebar-component></sidebar-component>
  <div class="pr-4 pl-4 pb-4 sm:ml-64">
    <h2 class="mt-4 text-left text-2xl text-bold text-tenth-color">
      Joiners API
    </h2>
    <p class="mt-2 mb-5 text-left">
      Joiners provides you with an efficient and secure way to make API calls to
      your account. Discover what you can do with credentials. This is where you
      will create and manage your private apps. You can create up to 5 unique
      credentials, each with its own permissions and user access.
      <a :href="apiDocsUrl" class="text-blue-500 underline" target="_blank">
        <strong> Check the documentation for more information. </strong>
      </a>
    </p>
    <ProgressBar
      :progress="progress"
      :currentCalls="currentCalls"
      :totalCalls="totalCalls"
    />

    <div class="mt-4 text-left">
      <button class="btn-users" type="button" @click="showCreateModal = true">
        Create credentials
      </button>
    </div>

    <CredentialCreateForm
      v-if="showCreateModal"
      :show-modal="showCreateModal"
      @close="showCreateModal = false"
    />

    <div class="mt-8">
      <CredentialCard
        v-for="credential in credentials"
        :key="credential.id"
        :credential="credential"
        @deleted="removeCredential"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";
import SidebarComponent from "@/components/util/SidebarComponent.vue";
import CredentialCard from "@/components/CredentialCard.vue";
import CredentialCreateForm from "@/components/CredentialCreateForm.vue";
import credential from "@/services/credential";

export default {
  components: {
    ProgressBar,
    SidebarComponent,
    CredentialCard,
    CredentialCreateForm,
  },
  data() {
    return {
      credentials: [],
      showCreateModal: false,
      currentCalls: 0,
      totalCalls: 1000,
      apiDocsUrl: process.env.VUE_APP_API_URL + "api-docs",
    };
  },
  computed: {
    // Dynamically calculate progress as a percentage
    progress() {
      if (this.totalCalls === 0) return 0; // Avoid division by 0
      return Math.min((this.currentCalls / this.totalCalls) * 100, 100);
    },
  },
  methods: {
    removeCredential(client) {
      this.credentials = this.credentials.filter((c) => c.client !== client);
    },
    async fetchCredentials() {
      try {
        const data = await credential.index();
        this.currentCalls = data.data.api_usage; // Fetch the API usage
        this.credentials = data.data.credentials; // Fetch credentials
      } catch (error) {
        console.error("Failed to fetch credentials:", error);
      }
    },
  },
  mounted() {
    this.fetchCredentials();
  },
};
</script>
