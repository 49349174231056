import axios from "axios";
import jsyaml from "js-yaml";
import apiClient from './apiClient';

export default {
  async index(query = '', page = 1) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/integrations`, {
        params: {
          page: page,
          query: query
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async show(id) {
    try {
      const response = await apiClient.get(
        `api/v1/platform_users/integrations/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const createResponse = await apiClient.post(
        `api/v1/platform_users/integrations`,
        { teacher: translatedFormData }
      );
      return createResponse;
    } catch (error) {
      throw error;
    }
  },

  async update(id, formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const updateResponse = await apiClient.put(
        `api/v1/platform_users/integrations/${id}`,
        { teacher: translatedFormData }
      );

      return updateResponse;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await apiClient.delete(`api/v1/platform_users/integrations/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
