import jsyaml from 'js-yaml';
import axios from 'axios';
import apiClient from './apiClient';

export default {

  async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;
  
      // Create a FormData object
      const form = new FormData();
      Object.keys(formData).forEach(key => {
        const newKey = payload[key] || key; // Fallback to original key if not found in payload
        form.append(newKey, formData[key]);
      });
  
      const createdResponse = await apiClient.post(
        `api/v1/platform_users/csv_imports`,
        form, // Pass the FormData object directly
        { headers: { 'Content-Type': 'multipart/form-data' } } // This line is usually not necessary, but added for clarity
      );
      return createdResponse;
    } catch (error) {
      throw error;
    }
  },
};
