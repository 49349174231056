<template>
    <sidebar-component></sidebar-component>
    <div class="pr-4 pl-4 pb-4 sm:ml-64">
      <div class="flex flex-col justify-between mt-10">
        <div class="flex space-x-8">
          <div class="w-1/2 pl-8">
            <CurrentPlanComponent
              :subscription-name="subscriptionName"
              :subscription-end-date="subscriptionEndDate"
            />
          </div>
          <div class="w-1/2">
            <BillingHistoryComponent />
          </div>
        </div>
        <div class="w-full mt-4">
          <div class="pl-8">
            <UsageChartComponent
              :subscription-end-date="subscriptionEndDate"
              :subscription-start-date="subscriptionStartDate"
            />
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
    import subscription from "@/services/subscription";
    import UsageChartComponent from "@/components/billing/UsageChartComponent.vue";
    import BillingHistoryComponent from "@/components/billing/BillingHistoryComponent.vue";
    import CurrentPlanComponent from "@/components/billing/CurrentPlanComponent.vue";
    import SidebarComponent from "@/components/util/SidebarComponent.vue";

    export default {
      components: {
        UsageChartComponent,
        BillingHistoryComponent,
        CurrentPlanComponent,
        SidebarComponent,
      },
      data() {
        return {
          subscriptionName: "",
          subscriptionStartDate: "",
          subscriptionEndDate: "",
        };
      },
      async created() {
        try {
          const response = await this.fetchSubscription();
          this.subscriptionName = response.name;
          this.subscriptionEndDate = this.formatDate(response.endDate);
          this.subscriptionStartDate = this.formatDate(response.startDate);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.subscriptionName = "Free trial";
            this.subscriptionEndDate = null;
          }
          console.error(error);
        }
      },
      methods: {
        async fetchSubscription() {
          const response = await subscription.show();
          return response.data.data.attributes;
        },
        formatDate(dateString) {
          let date = new Date(dateString);
          let options = { year: "numeric", month: "long", day: "numeric" };
          return date.toLocaleDateString(undefined, options);
        },
      },
    };
  </script>

  <style scoped>
  </style>
