<template>
  <div class="relative pr-8">
    <div
      class="relative overflow-x-auto shadow-md sm:rounded-lg"
    >
      <div class="flex items-center justify-between p-4 bg-first-color">
        <span class="font-bold text-tenth-color">Selected integrations</span>
        <MassActionIntegrationButton
        :selectedFilter="selectedFilter"
        :hasItemsAvailableForDelete="hasItemsAvailableForDelete"
        :hasItemsAvailableForCreate="hasItemsAvailableForCreate"
        @mass-action="handleMassAction"
      />
      </div>
      <div class="border-t border-fourth-color w-4/5 mx-auto"></div>
      <PlatformUserIntegrationFilter :hasItemsSelected="hasItemsSelected" @filter-changed="handleFilterChange" @clear-selection="clearSelection" />
      <table
        class="w-full text-sm text-left rtl:text-right text-tenth-color dark:text-tenth-color"
      >
        <thead
          class="text-s text-tenth-color bg-first-color dark:bg-first-color dark:text-tenth-color"
        >
          <tr>
            <th scope="col" class="px-4 py-3"></th>
            <th
              scope="col"
              class="px-6 py-3"
              v-for="key in filteredAttributeKeys"
              :key="key"
            >
              {{ $t(key) }}
            </th>
            <th scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
            <th scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
            <th v-if="hasErrorMessages" scope="col" class="px-6 py-3">
              <!-- You can leave this empty or add a header for the Edit column -->
            </th>
          </tr>
        </thead>
        <tbody v-for="item in data" :key="item.id">
          <tr
            class="odd:bg-first-color odd:dark:bg-first-color even:bg-second-color even:dark:bg-second-color border-b dark:border-second-color"
          >
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input
                  v-model="item.selected"
                  :id="'checkbox-' + item.id"
                  type="checkbox"
                  :disabled="isButtonDisabled(item)"
                  class="w-4 h-4 text-tenth-color bg-first-color border-first-color rounded focus:ring-tenth-color dark:focus:ring-tenth-color dark:ring-offset-tenth-color dark:focus:ring-offset-tenth-color0 focus:ring-2 dark:bg-tenth-color dark:border-tenth-color"
                />
                <label :for="'checkbox-' + item.id" class="sr-only"
                  >checkbox</label
                >
              </div>
            </td>
            <td
              class="px-6 py-4"
              v-for="key in filteredAttributeKeys"
              :key="key"
            >
              <span
                v-if="typeof item.attributes[key] === 'object'"
                class="bold-text"
              >
                {{ displayObject(item.attributes[key]) }}
              </span>
              <span v-else-if="key !== 'connected'" class="bold-text">
                {{ item.attributes[key] }}
              </span>
              <div v-else>
                <div v-if="item.attributes[key]" class="flex items-center">
                  <div class="h-2.5 w-2.5 rounded-full bg-green-color me-2"></div>
                  <span class="bold-text">Online</span>
                </div>
                <div v-else class="flex items-center">
                  <div class="h-2.5 w-2.5 rounded-full bg-red-color me-2"></div>
                  <span class="bold-text">Offline</span>
                </div>
              </div>
            </td>
            <td>
              <button
                class="btn"
                @click="
                  item.isCreated ? confirmDelete(item) : confirmCreate(item)
                "
                :disabled="isButtonDisabled(item)"
              >
                <div v-if="isLoading[item.id]">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6 text-first-color animate-spin dark:text-first-color fill-tenth-color"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  {{ buttonText(item) }}
                </div>
              </button>
            </td>
            <td>
              <div>
                <button
                  v-if="
                    isRingoverAndNoPhoneNumber(item) || isAircallAdminUSer(item)
                  "
                  @click="toggleAccordion(item.id)"
                >
                  <svg
                    :class="{ 'rotate-180': !accordionOpen[item.id] }"
                    class="w-3 h-3 shrink-0 transition-transform duration-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
                <div v-if="errorMessages[item.id]" class="text-red-color">
                  {{ getFirstErrorMessage(errorMessages[item.id]) }}
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="accordionOpen[item.id] && item.attributes.name == 'Ringover'"
          >
            <td colspan="100%">
              <div v-if="accordionOpen[item.id]" class="text-tenth-color">
                <strong class="px-6 py-2 block">Available numbers:</strong>
                <div class="flex flex-wrap px-6 py-2">
                  <div
                    class="flex items-center me-4"
                    v-for="(detail, _index) in item.attributes.details"
                    :key="detail.id"
                  >
                    <input
                      :id="`radio-${detail.id}`"
                      type="radio"
                      :value="detail.id"
                      v-model="selectedDetail"
                      name="colored-radio"
                      class="w-4 h-4 text-green-color bg-first-color border-first-color focus:ring-green-color dark:focus:ring-green-color dark:ring-offset-tenth-color focus:ring-2 dark:bg-tenth-color dark:border-first-color"
                    />
                    <label
                      :for="`radio-${detail.id}`"
                      class="ms-2 text-sm font-medium text-tenth-color dark:text-tenth-color"
                      >{{ detail.number }}</label
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="accordionOpen[item.id] && item.attributes.name == 'Aircall'"
          >
            <td colspan="4" class="px-6 py-2">
              <strong
                >This is the admin user for Aircall. If you delete this user,
                you will lose access the account. Actions are disabled for
                safety reasons.</strong
              >
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        @goToPage="goToPage"
        @nextPage="nextPage"
        @prevPage="prevPage"
      />
    </div>
  </div>
</template>

<script>
import platformIntegration from "@/services/platformIntegration";
import integrationAccess from "@/services/integrationAccess";
import Pagination from "@/components/util/Pagination.vue";
import MassActionIntegrationButton from "@/components/platform-integration/MassActionIntegrationButton.vue";
import PlatformUserIntegrationFilter from "@/components/platform-integration/PlatformUserIntegrationFilter.vue";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    Pagination,
    MassActionIntegrationButton,
    PlatformUserIntegrationFilter,
  },
  data() {
    return {
      data: [],
      selectedId: null,
      currentPage: 1,
      totalPages: 0,
      errorMessages: {},
      accordionOpen: {},
      selectedDetail: null,
      isLoading: {},
      selectedFilter: "", // Default filter
    };
  },
  computed: {
    hasErrorMessages() {
      return Object.values(this.errorMessages).some(Boolean);
    },
    filteredAttributeKeys() {
      return this.attributeKeys.filter((key) => key !== "details");
    },
    attributeKeys() {
      return this.data.length > 0 ? Object.keys(this.data[0].attributes) : [];
    },
    hasItemsAvailableForDelete() {
      return this.data.some(
        (item) => item.isCreated && !this.isButtonDisabled(item)
      );
    },
    hasItemsAvailableForCreate() {
      return this.data.some(
        (item) => !item.isCreated && !this.isButtonDisabled(item)
      );
    },
    hasItemsSelected() {
      return this.data.some((item) => item.selected);
    },
  },
  watch: {
    platformIntegration: {
      handler(newVal) {
        this.fetchData(newVal, this.currentPage);
      },
      immediate: true,
    },
    selectedFilter(newValue) {
      this.applyFilter(newValue);
    },
  },
  methods: {
    toggleAccordion(id) {
      if (this.accordionOpen.hasOwnProperty(id)) {
        this.accordionOpen[id] = !this.accordionOpen[id];
      } else {
        this.accordionOpen[id] = true;
      }
    },
    isButtonDisabled(item) {
      return (
        this.isGithubAndNoUsername(item) ||
        this.isRingoverAndNoPhoneNumber(item) ||
        this.isNotConnected(item) ||
        this.isGithubInvitationSentAndNotCreated(item) ||
        this.isInvitationPendingAndNotCreated(item) ||
        this.isAircallAdminUSer(item)
      );
    },
    buttonText(item) {
      if (item.attributes.name === "Github") {
        const githubText = this.githubButtonText(item);
        if (githubText) {
          return githubText;
        }
      } else if (this.isRingoverAndNoPhoneNumber(item) && !item.isCreated) {
        return "Choose a phone number";
      }
      return this.defaultButtonText(item);
    },
    githubButtonText(item) {
      if (this.user.githubUsername === "") {
        return "Add user name to use this integration";
      } else if (
        (this.user.githubInvitationSent && !item.isCreated) ||
        (item.invitationPending && !item.isCreated)
      ) {
        return "Pending invitation";
      }
      return null;
    },
    defaultButtonText(item) {
      return item.isCreated ? "Delete" : "Create";
    },
    async withLoading(task, item) {
      this.isLoading[item.id] = true;
      try {
        await task(item);
      } catch (error) {
        this.errorMessages[item.id] = error.response.data.errors[0];
      } finally {
        this.isLoading[item.id] = false;
      }
    },

    async confirmCreate(item) {
      await this.withLoading(async (item) => {
        const formData = {
          user: this.$route.params.id,
          platformUserIntegration: item.id,
          detail: this.selectedDetail,
        };
        await integrationAccess.create(formData);
        const methodName = `${item.attributes.name}ProcessCreate`;
        this[methodName](item);
      }, item);
    },

    async confirmDelete(item) {
      await this.withLoading(async (item) => {
        const response = await integrationAccess.delete(
          this.$route.params.id,
          item.id
        );
        const methodName = `${item.attributes.name}ProcessDelete`;
        this[methodName](item, response);
      }, item);
    },
    GithubProcessDelete(item, _response) {
      this.user.githubInvitationSent = false;
      item.invitationPending = false;
      item.isCreated = false;
    },
    GithubProcessCreate(item) {
      item.invitationPending = true;
    },
    AircallProcessCreate(item) {
      item.isCreated = true;
    },
    AircallProcessDelete(item, _response) {
      item.isCreated = false;
    },
    HubspotProcessDelete(item, _response) {
      item.isCreated = false;
    },
    HubspotProcessCreate(item) {
      item.isCreated = true;
    },
    SlackProcessCreate(item) {
      item.isCreated = true;
    },
    SlackProcessDelete(item, _response) {
      item.isCreated = false;
    },
    GoogleProcessCreate(item) {
      item.isCreated = true;
    },
    GoogleProcessDelete(item, _response) {
      item.isCreated = false;
    },
    MicrosoftProcessCreate(item) {
      item.isCreated = true;
    },
    MicrosoftProcessDelete(item, _response) {
      item.isCreated = false;
    },
    RingoverProcessDelete(item, response) {
      item.isCreated = false;
      item.attributes.details = response.data.data.attributes.details;
      this.accordionOpen[item.id] = true;
    },
    RingoverProcessCreate(item) {
      item.isCreated = true;
      item.attributes.details = item.attributes.details.filter(
        (detail) => detail.id !== this.selectedDetail
      );
      this.selectedDetail = null;
      this.accordionOpen[item.id] = false;
    },
    isRingoverAndNoPhoneNumber(item) {
      return (
        item.attributes.name === "Ringover" &&
        this.selectedDetail === null &&
        !item.isCreated
      );
    },
    isGithubAndNoUsername(item) {
      return (
        item.attributes.name === "Github" && this.user.githubUsername === ""
      );
    },
    isNotConnected(item) {
      return item.attributes.connected === false;
    },
    isGithubInvitationSentAndNotCreated(item) {
      return (
        this.user.githubInvitationSent == true &&
        item.isCreated == false &&
        item.attributes.name === "Github"
      );
    },
    isInvitationPendingAndNotCreated(item) {
      return (
        item.invitationPending === true &&
        item.isCreated == false &&
        item.attributes.name === "Github"
      );
    },
    isAircallAdminUSer(item) {
      return (
        item.attributes.name === "Aircall" &&
        this.user.email === item.attributes.details.email
      );
    },
    getFirstErrorMessage(errorObject) {
      if (typeof errorObject === "string") {
        return errorObject;
      } else if (typeof errorObject === "object" && errorObject !== null) {
        return Object.values(errorObject)[0];
      } else {
        return "";
      }
    },
    async fetchData() {
  try {
    this.data = [];
    this.totalPages = 0;

    const response = await platformIntegration.index(
      "",
      this.currentPage,
      true
    );
    const data = response.data.data;
    this.totalPages = response.data.meta.total_pages;

    // Start processing all integrations at once
    const promises = data.map(async (item) => {
      try {
        await integrationAccess.show(this.$route.params.id, item.id);
        item.isCreated = true;
      } catch (error) {
        item.isCreated = false;
      }
      // Update the UI as each integration is processed
      this.data.push(item);
    });

    // Wait for all integrations to be processed
    await Promise.all(promises);
  } catch (error) {
    console.error(error);
  }
},
    displayObject(obj) {
      if (obj && typeof obj === "object") {
        const key = Object.keys(obj).find((key) => key !== "id");
        return obj[key];
      }
      return "";
    },
    goToPage(page) {
      this.currentPage = page;
      this.fetchData(platformIntegration, this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(platformIntegration, this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData(platformIntegration, this.currentPage);
      }
    },
    handleFilterChange(filter) {
      if (this.selectedFilter === filter) {
        this.selectedFilter = "";
        this.clearSelection();
      } else {
        this.selectedFilter = filter;
        this.applyFilter(filter);
      }
    },
    clearSelection() {
      this.data.forEach((item) => {
        item.selected = false;
      });
    },
    applyFilter(filter) {
      if (filter === "all-creation") {
        this.data.forEach((item) => {
          item.selected = !item.isCreated && !this.isButtonDisabled(item);
        });
      } else if (filter === "all-deletion") {
        this.data.forEach((item) => {
          item.selected = item.isCreated && !this.isButtonDisabled(item);
        });
      }
    },
    handleMassAction() {
      if (this.selectedFilter === "all-creation") {
        this.massCreate();
      } else if (this.selectedFilter === "all-deletion") {
        this.massDelete();
      }
    },
    massCreate() {
      const itemsToCreate = this.data.filter(
        (item) => item.selected && !this.isButtonDisabled(item)
      );
      itemsToCreate.forEach((item) => {
        this.confirmCreate(item);
      });
    },
    massDelete() {
      const itemsToDelete = this.data.filter(
        (item) => item.selected && !this.isButtonDisabled(item)
      );
      itemsToDelete.forEach((item) => {
        this.confirmDelete(item);
      });
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.2s;
}
</style>
