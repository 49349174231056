<template>
  <div class="pt-10 flex">
    <sidebar-component></sidebar-component>
    <div
      class="pr-4 pl-8 pb-4 container mx-auto p-8 bg-first-color rounded-lg w-full"
    >
      <h2
        class="mb-4 text-4xl leading-none tracking-tight text-center md:text-3xl lg:text-4xl text-tenth-color"
      >
        Import users to Joiners
      </h2>
      <p class="mt-6 mb-8 text-lg leading-8 text-tenth-color text-center">
        Import users using a CSV file. Make sure the file is formatted correctly
        before uploading. The file should contain the following columns:
        <span class="font-semibold">email</span>,
        <span class="font-semibold">first name</span>,
        <span class="font-semibold">last name</span>,
        <span class="font-semibold">phone number</span>,
        <span class="font-semibold">job title</span>,
        <span class="font-semibold">role</span>,
        <span class="font-semibold">contract type</span>,
        <span class="font-semibold">arrival date</span>, and
        <span class="font-semibold">departure date</span>.
      </p>
      <div class="flex items-center justify-center w-full mb-8">
        <label
          v-if="!loading"
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-tenth-color border-dashed rounded-lg cursor-pointer bg-second-color dark:hover:bg-third-color dark:bg-second-color hover:bg-third-color dark:border-tenth-color dark:hover:border-tenth-color"
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6 text-fifth-color dark:text-fifth-color hover:text-tenth-color hover:dark:text-tenth-color">
            <svg
              class="w-8 h-8 mb-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p class="mb-2 text-sm ">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs">
              CSV files only.
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            @change="handleFileUpload"
            ref="fileInput"
          />
        </label>
        <div v-else class="flex justify-center mb-4">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-6 h-6 text-first-color animate-spin dark:text-first-color fill-tenth-color"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <!-- This div centers its children -->
        <div
          v-if="errorMessage"
          id="alert-4"
          class="w-1/2 flex items-center p-4 mb-4 text-red-color rounded-lg bg-first-color dark:bg-red-color dark:text-first-color"
          role="alert"
        >
          <svg
            class="flex-shrink-0 w-4 h-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
            />
          </svg>
          <span class="sr-only">Error</span>
          <div class="ms-3 text-sm font-medium text-left">
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <div class="text-gray-500">
        <div
          id="alert-4"
          class="flex items-center p-4 mb-4 text-tenth-color rounded-lg bg-first-color dark:bg-first-color dark:text-tenth-color"
          role="alert"
        >
          <svg
            class="flex-shrink-0 w-4 h-4 text-tenth-color"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
            />
          </svg>
          <span class="sr-only">Info</span>
          <div class="ms-3 text-sm font-medium text-left text-tenth-color">
            Note that spaces in field names should be replaced with underscores.
            All fields are mandatory, except <strong>departure_date</strong> may
            be omitted for permanent contracts.
            <strong>arrival_date</strong> and
            <strong>departure_date</strong> must be in a valid date format.
            <strong>contract_type</strong> must be either
            <strong>Permanent</strong> or <strong>Temporary</strong>. For
            Permanent contracts, <strong>departure_date</strong> is not
            required. If any row is incorrectly formatted, the CSV import will
            fail to create any users.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/util/SidebarComponent.vue";
import csv from "@/services/csv";

export default {
  data() {
    return {
      errorMessage: "", // Step 1: Add a property for the error message
      loading: false, // Add a property for loading state
    };
  },
  components: {
    SidebarComponent,
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === "text/csv") {
        this.uploadFile(file);
      } else {
        alert("Please upload a valid CSV file.");
        event.target.value = null;
      }
    },
    async uploadFile(file) {
      this.loading = true; // Set loading to true
      try {
        const response = await csv.create({ file: file });
        if (response.status === 200) {
          alert("File uploaded successfully");
          this.errorMessage = ""; // Clear error message on success
        } else {
          const errorText = await response.text();
          this.errorMessage = `Failed to upload file: ${response.status} ${response.statusText} - ${errorText}`; // Set error message
        }
      } catch (error) {

        this.errorMessage = error.response?.data?.error || error.message;
      } finally {
        this.loading = false; // Set loading to false
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: #f7fafc;
}

.container {
  background-color: #ffffff;
  padding: 2rem 8rem;
  width: 100%; /* Set width to 75% */
  margin-left: 16rem; /* Adjust margin-left to account for the sidebar */
}

h2 {
  margin-bottom: 1rem;
  text-align: center;
}

p {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  background-color: #f9fafb;
  cursor: pointer;
}

label:hover {
  background-color: #f3f4f6;
}

label svg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
  color: #6b7280;
}

label p {
  margin-bottom: 0.5rem;
  color: #6b7280;
}

label p span {
  font-weight: 600;
}

label input {
  display: none;
}
</style>
