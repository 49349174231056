<template>
  <sidebar-component></sidebar-component>
  <div class="pr-4 pl-4 pb-4 sm:ml-64">
    <div class="relative pt-10">
      <div class="pb-4 bg-first-color dark:bg-first-color flex items-center justify-between">
        <div class="relative mt-1">
          <label for="table-search" class="sr-only">Search</label>
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-tenth-color dark:text-tenth-color" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            v-model="searchQuery"
            @input="onSearchInput"
            class="search-bar"
            placeholder="Search for items"
          />
        </div>
        <div class="flex items-center space-x-2">
          <button
            @click="showModal = true"
            class="btn-users"
            type="button"
          >
            Create
          </button>
          <button
            class="btn-users"
            type="button"
            @click="$router.push('/import')"
          >
            Import
          </button>
        </div>
        <div v-if="showModal">
          <div
            class="fixed inset-0 bg-tenth-color bg-opacity-50 z-40 backdrop-blur-md"
          ></div>
          <component
            :is="`UserCreateForm`"
            @itemCreated="fetchData"
            @close="showModal = false"
          />
        </div>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table
          class="w-full text-sm text-left rtl:text-right text-tenth-color dark:text-tenth-color"
        >
          <thead
            class="text-s text-tenth-color bg-first-color dark:bg-first-color dark:text-tenth-color border-b border-fourth-color"
          >
            <tr>
              <th
                scope="col"
                class="px-6 py-3"
                v-for="key in attributeKeys"
                :key="key"
              >
                {{ $t(key) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in data"
              :key="item.id"
              class="odd:bg-first-color odd:dark:bg-first-color even:bg-first-color even:dark:bg-second-color border-b dark:border-second-color"
            >
              <td
                class="px-6 py-4 description-column"
                v-for="key in attributeKeys"
                :key="key"
              >
                <router-link
                  class="hover:underline bold-text"
                  :to="{ name: 'user', params: { id: item.id } }"
                >
                  <span v-if="typeof item.attributes[key] === 'object'">{{
                    displayObject(item.attributes[key])
                  }}</span>
                  <span v-else-if="key !== 'status'">{{
                    item.attributes[key]
                  }}</span>
                  <div v-else>
                    <div v-if="item.attributes[key]" class="flex items-center">
                      <div
                        class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"
                      ></div>
                      <span>Active</span>
                    </div>
                    <div v-else class="flex items-center">
                      <div
                        class="h-2.5 w-2.5 rounded-full bg-red-500 me-2"
                      ></div>
                      <span>Inactive</span>
                    </div>
                  </div>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @goToPage="goToPage"
          @nextPage="nextPage"
          @prevPage="prevPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/services/user";

import Pagination from "@/components/util/Pagination.vue";
import UserCreateForm from "@/components/users/UserCreateForm.vue";
import SidebarComponent from "@/components/util/SidebarComponent.vue";

export default {
  components: {
    Pagination,
    UserCreateForm,
    SidebarComponent,
  },
  data() {
    return {
      data: [],
      showModal: false,
      currentPage: 1,
      totalPages: 0,
      searchQuery: "",
    };
  },
  computed: {
    attributeKeys() {
      return this.data.length > 0 ? Object.keys(this.data[0].attributes) : [];
    },
  },
  watch: {
    dataType: {
      handler(newVal) {
        this.fetchData(this.currentPage, this.searchQuery);
      },
      immediate: true,
    },
    searchQuery: {
      handler(newVal) {
        this.fetchData(this.currentPage, newVal);
      },
    },
  },
  methods: {
    onSearchInput() {
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    async fetchData(page, query = "") {
      try {
        this.data = [];
        this.totalPages = 0;

        const response = await user.index(query, page);
        const data = response.data.data;
        this.totalPages = response.data.meta.total_pages;
        this.data = data;
      } catch (error) {
        console.error(error);
      }
    },
    displayObject(obj) {
      if (obj && typeof obj === "object") {
        const key = Object.keys(obj).find((key) => key !== "id");
        return obj[key];
      }
      return "";
    },
    goToPage(page) {
      this.currentPage = page;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.font-weight-700 {
  font-weight: 700;
}

.color-050505 {
  color: #050505;
}

.text-2xl {
  font-size: 2em;
  /* adjust as needed */
}

.font-semibold {
  font-weight: 600;
  /* adjust as needed */
}

/* Added styles for table cells to prevent text wrap and manage overflow */
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-column {
  max-width: 300px; /* Adjust as needed */
}
</style>
