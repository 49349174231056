<template>
  <div>
      <div class="flex justify-end items-center py-2 px-4 bg-tenth-color lg:px-6 lg:hidden dark:bg-tenth-color">
          <a href="/contact" class="mr-2 text-sm font-medium text-first-color hover:underline dark:text-first-color">Talk to sales</a>
          <a href="#" class="inline-flex items-center p-2 text-sm font-medium text-first-color rounded-lg dark:text-first-color hover:bg-tenth-color focus:ring-4 focus:ring-first-color dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-first-color">
              <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd"></path></svg>
          </a>
          <a href="tel:+33682125869" class="inline-flex items-center p-2 text-sm font-medium text-first-color rounded-lg dark:text-first-color hover:bg-tenth-color focus:ring-4 focus:ring-first-color dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-first-color">
              <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
          </a>
      </div>
      <nav class="bg-tenth-color px-4 lg:px-6 py-2.5 dark:bg-tenth-color">
          <div class="grid grid-cols-3 items-center mx-auto max-w-screen-xl">
              <a href="/" class="flex items-center lg:justify-center lg:order-2">
                  <img src="/img/icons/white_logo.png" class="mr-3 h-6 sm:h-9" alt="Joiners Logo" />
                  <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-first-color">Joiners</span>
              </a>
              <div class="flex col-span-2 justify-end items-center lg:order-3 lg:col-span-1">
                  <a href="/contact" class="hidden mr-2 text-sm font-medium text-first-color hover:underline dark:text-first-color lg:inline">Talk to sales</a>
                  <a href="#" class="hidden items-center p-2 text-sm font-medium text-first-color rounded-lg lg:inline-flex dark:text-first-color hover:bg-tenth-color focus:ring-4 focus:ring-first-color dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-first-color">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd"></path></svg>
                  </a>
                  <a href="tel:+33682125869" class="hidden items-center p-2 text-sm font-medium text-first-color rounded-lg lg:inline-flex dark:text-first-color hover:bg-tenth-color focus:ring-4 focus:ring-first-color dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-first-color">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
                  </a>
                  <a href="/signin" class="text-first-color dark:text-first-color hover:bg-tenth-color focus:ring-4 focus:ring-first-color font-medium rounded-lg text-sm px-4 py-2 lg:py-2.5 mr-2 dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-first-color">Login</a>
                  <a href="/signin" class="text-first-color bg-tenth-color hover:bg-tenth-color focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-tenth-color focus:outline-none dark:focus:ring-primary-800">Sign up</a>
                  <button @click="toggleMenu" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-first-color rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-first-color dark:hover:bg-tenth-color dark:focus:ring-first-color" aria-controls="mobile-menu-2" aria-expanded="false">
                      <span class="sr-only">Open main menu</span>
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                      </svg>
                      <svg v-show="menuOpen" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                  </button>
              </div>
              <div :class="{ 'hidden': !menuOpen }" class="col-span-3 justify-between items-center w-full lg:flex lg:w-auto lg:order-1 lg:col-span-1" id="mobile-menu-2">
                  <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                      <li>
                          <a href="/" class="block py-2 pr-4 pl-3 border-b border-first-color text-first-color dark:text-first-color hover:bg-tenth-color lg:hover:bg-transparent lg:border-0 lg:p-0 dark:hover:bg-tenth-color dark:hover:text-first-color lg:dark:hover:bg-transparent dark:border-first-color" aria-current="page">Home</a>
                      </li>
                      <li>
                          <a href="/pricing" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-first-color hover:bg-tenth-color lg:hover:bg-transparent lg:border-0 lg:hover:text-first-color lg:p-0 dark:text-first-color lg:dark:hover:text-first-color dark:hover:bg-tenth-color dark:hover:text-first-color lg:dark:hover:bg-transparent dark:border-first-color">Pricing</a>
                      </li>
                      <li>
                          <a href="/contact" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-first-color hover:bg-tenth-color lg:hover:bg-transparent lg:border-0 lg:p-0 dark:text-first-color lg:dark:hover:text-first-color dark:hover:bg-tenth-color dark:hover:text-first-color lg:dark:hover:bg-transparent dark:border-first-color">Contact</a>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>
  </div>
</template>

<script>
export default {
  data() {
      return {
          menuOpen: false
      };
  },
  methods: {
      toggleMenu() {
          this.menuOpen = !this.menuOpen;
      }
  }
}
</script>
