import jsyaml from 'js-yaml';
import axios from 'axios';
import apiClient from './apiClient';

export default {
  async index(query = '', page = 1, minimal = true) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/users`, {
        params: {
          page: page,
          query: query,
          minimal: minimal
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async show(id) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/users/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;
      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const createdResponse = await apiClient.post(
        `api/v1/platform_users/users`,
        { user: translatedFormData }
      );
      return createdResponse;
    } catch (error) {
      throw error;
    }
  },

  async update(id, formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const updatedResponse = await apiClient.put(
        `api/v1/platform_users/users/${id}`,
        { user: translatedFormData }
      );
      return updatedResponse;
    } catch (error) {
      throw error;
    }
  },

  async acceptInvitation(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const updatedResponse = await apiClient.put(
        `auth/invitation`,
        { user: translatedFormData }
      );
      return updatedResponse;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await apiClient.delete(`api/v1/platform_users/users/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};