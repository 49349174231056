<template>
  <header>
    <site-nav-bar></site-nav-bar>
    </header>
    <section class="bg-tenth-color dark:bg-tenth-color relative isolate px-6 pt-14 lg:px-8 text-first-color flex flex-col items-center">
      <div class="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none" aria-hidden="true">
          <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
        </div>
        <div class="max-w-3xl mx-auto text-center">
          <h2 class="text-3xl font-extrabold tracking-tight text-first-color sm:text-4xl dark:text-first-color">
            Choose the right plan for your business
          </h2>
          <p class="mt-4 text-base text-first-color sm:text-xl dark:text-first-color">
            Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and
            drive
            economic growth.
          </p>
        </div>
        <div class="grid gap-8 mt-8 mb-8 lg:grid-cols-3 sm:mt-12">
          <div
            v-for="plan in plans"
            :key="plan.id"
            @click="selectPlan(plan)"
            :class="[
              'flex flex-col max-w-lg p-6 mx-auto text-center text-first-color bg-tenth-color border border-first-color rounded-lg shadow-sm dark:border-first-color xl:p-8 dark:text-first-color dark:bg-tenth-color',
              selectedPlan.id === plan.id ? 'ring-4 ring-first-color' : ''
            ]"
          >
            <h3 class="mb-4 text-2xl font-semibold">{{ plan.attributes.name }}</h3>
            <p class="text-first-color text-light sm:text-lg dark:text-first-color">{{ plan.attributes.description }}</p>
            <div class="flex items-baseline justify-center my-8">
              <span class="mr-2 text-3xl font-extrabold">{{ plan.attributes.price }}</span>
              <span class="text-first-color">/month per user</span>
            </div>
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li
                v-for="feature in plan.attributes.features"
                :key="feature.name"
                class="flex items-center space-x-3"
                :class="{ 'text-first-color line-through': !feature.available }">
                  <svg
                    class="flex-shrink-0 w-5 h-5"
                    :class="feature.available ? 'text-first-color' : 'text-red-color'"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                    ></path>
                  </svg>
                <span>{{ feature.name }}</span>
              </li>
            </ul>
            <a
              href="#"
              @click.prevent="handleBuyNow(plan)"
              class="text-first-color bg-tenth-color hover:bg-tenth-color font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-tenth-color dark:hover:bg-eighth-color border border-first-color">
                {{
                  plan.attributes.name === 'Enterprise'
                    ? 'Book a call'
                    : 'Get started'
                }}
            </a>
          </div>
          <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
      </div>
        </div>
      </div>
    </section>
   <site-footer></site-footer>
</template>

<script>
import SiteNavBar from "@/components/SiteNavBar";
import SiteFooter from "@/components/SiteFooter";
import planService from "@/services/plan";
import auth from "@/services/users/token";
import stripe from "@/services/stripe";

export default {
  components: {
   SiteNavBar,
   SiteFooter
 },
  data() {
    return {
      plans: [],
      selectedPlan: { attributes: { features: [] } }, // Initialize with empty attributes to avoid undefined errors
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await planService.index();
        this.plans = response.data.data;
        if (this.plans.length > 0) {
          this.selectedPlan = this.plans[0]; // Initialize with the first plan if available
        }
      } catch (error) {
        console.error(error);
      }
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    isAuthenticated() {
      const tokens = auth.getTokens();
      return !!tokens["access-token"];
    },
    async handleBuyNow(plan) {
  try {
    if (this.isAuthenticated()) {
      if (plan.attributes.name === "Enterprise") {
        this.$router.push('/contact');
      } else {
        const response = await stripe.checkout({
          planId: plan.attributes.stripePlanId,
        });
        window.location.href = response.data.url;
      }
    } else {
      const queryString = `?plan=${encodeURIComponent(
        plan.attributes.stripePlanId
      )}`;
      window.location.href = `/signup${queryString}`;
    }
  } catch (error) {
    console.error("Error checking authentication", error);
  }
  },
  },
};
</script>

<style scoped>
.heading-font {
  font-size: 2.5rem;
  font-weight: 700;
}

.paragraph-font {
  font-size: 1.125rem;
  font-weight: 400;
}

.bold-text {
  font-weight: 900;
}

</style>
