import axios from "axios";
import jsyaml from "js-yaml";
import authService from "../services/users/token";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  async checkout(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const createResponse = await axios.post(
        `${API_URL}api/v1/platform_users/stripe/checkouts`,
        { stripe: translatedFormData },
        {
          headers: authService.getTokens(),
        }
      );
      return createResponse;
    } catch (error) {
      throw error;
    }
  },
  async billingPortal() {
    try {


      const createResponse = await axios.post(
        `${API_URL}api/v1/platform_users/stripe/billing_portal`,
        { stripe: '' },
        {
          headers: authService.getTokens(),
        }
      );
      return createResponse;
    } catch (error) {
      throw error;
    }
  },
};
