import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PricingView from '../views/PricingView.vue'
import UserView from '../views/UserView.vue'
import SignupView from '../views/auth/SignupView.vue'
import UsersView from '../views/dashboard/UsersView.vue'
import ToolsView from '../views/dashboard/ToolsView.vue'
import MarketPlaceView from '../views/dashboard/MarketPlaceView.vue'
import SignInView from '../views/auth/SignInView.vue'
import HubspotIntegrationsView from '../views/integrations/oauth/HubspotIntegrationsView.vue'
import GithubIntegrationsView from '../views/integrations/oauth/GithubIntegrationsView.vue'
import SlackIntegrationsView from '../views/integrations/oauth/SlackIntegrationsView.vue'
import RingoverIntegrationsView from '../views/integrations/api/RingoverIntegrationsView.vue'
import AircallIntegrationsView from '../views/integrations/api/AircallIntegrationsView.vue'
import GoogleIntegrationsView from '../views/integrations/oauth/GoogleIntegrationsView.vue'
import DiscordIntegrationsView from '../views/integrations/oauth/DiscordIntegrationsView.vue'
import MicrosoftIntegrationsView from '../views/integrations/oauth/MicrosoftIntegrationsView.vue'
import OauthSignInView from '../views/auth/OauthSignInView.vue'
import ImportView from '../views/dashboard/ImportView.vue'
import ContactView from '../views/ContactView.vue'
import BillingView from '../views/dashboard/BillingView.vue'
import ApiView from '../views/dashboard/ApiView.vue'
import PrivacyPolicy from '@/views/PrivacyPolicyView.vue'
import TermsView from '@/views/TermsView.vue'
import auth from '../services/users/token'

function checkAuthAndRedirect(to, from, next) {
  if (auth.getTokens()['access-token']) {
    if (from.path === '/invitation') {
      next();
    } else {
      next({ name: 'users' });
    }
  } else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
    beforeEnter: checkAuthAndRedirect,
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInView,
    beforeEnter: checkAuthAndRedirect,
  },
  {
    path: '/oauth/signin',
    name: 'oauth-signin',
    component: OauthSignInView,
    beforeEnter: checkAuthAndRedirect
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: { requiresAuth: true },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/market-place',
    name: 'market-place',
    component: MarketPlaceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/api',
    name: 'api',
    component: ApiView,
    meta: { requiresAuth: true }, 
  },
  {
    path: '/user/:id',
    name: 'user',
    component: UserView,
    meta: { requiresAuth: true },
  },
  {
    path: '/import',
    name: 'import',
    component: ImportView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/hubspot',
    name: 'hubspot',
    component: HubspotIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/github',
    name: 'github',
    component: GithubIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/ringover',
    name: 'ringover',
    component: RingoverIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/aircall',
    name: 'aircall',
    component: AircallIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/google',
    name: 'google',
    component: GoogleIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/slack',
    name: 'slack',
    component: SlackIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/discord',
    name: 'discord',
    component: DiscordIntegrationsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/platform-integration/microsoft',
    name: 'microsoft',
    component: MicrosoftIntegrationsView,
    meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.getTokens()['access-token']) {
      next({ name: 'signin' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
