<template>
    <header>
        <site-nav-bar></site-nav-bar>
    </header>
    <section class="bg-tenth-color dark:bg-tenth-color relative isolate px-6 pt-14 lg:px-8 text-first-color flex flex-col items-center">
    <div class="bg-blend-multiply">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none" aria-hidden="true">
              <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
            </div>
        <div class="max-w-screen-sm px-4 mx-auto text-center pb-72 lg:pb-80 pt-20 sm:pt-24 lg:pt-32">
          <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-first-color sm:text-3xl">Contact Us</h2>
          <p class="mb-16 text-first-color sm:text-xl">We are at your disposal for any request.</p>
        </div>
      </div>
    <div class="max-w-screen-xl px-4 py-16 mx-auto -mt-96 sm:py-24">
        <form action="#" class="grid max-w-screen-md grid-cols-1 gap-6 md:gap-8 p-4 md:p-8 mx-auto mb-16 bg-tenth-color border border-first-color rounded-lg shadow-md lg:mb-28 dark:bg-tenth-color dark:border-first-color sm:grid-cols-2" @submit.prevent="submitForm">
          <div>
            <label for="firstname" class="block mb-2 text-sm font-medium text-first-color dark:text-first-color">First
              Name</label>
            <input v-model="formData.firstname" type="text" id="firstname"
              class="block w-full p-3 text-sm text-tenth-color border border-first-color rounded-lg bg-first-color focus:ring-first-color focus:border-first-color dark:bg-tenth-color dark:border-first-color dark:placeholder-fourth-color dark:text-first-color dark:focus:ring-first-color dark:focus:border-first-color"
              placeholder="Bonnie" required>
          </div>
          <div>
            <label for="lastname" class="block mb-2 text-sm font-medium text-first-color dark:text-first-color">Last Name</label>
            <input v-model="formData.lastname" type="text" id="lastname"
              class="block w-full p-3 text-sm text-tenth-color border border-gray-300 rounded-lg bg-gray-50 focus:ring-first-color focus:border-first-color dark:bg-tenth-color dark:border-first-color dark:placeholder-fourth-color dark:text-first-color dark:focus:ring-first-color dark:focus:border-first-color"
              placeholder="Green" required>
          </div>
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-first-color dark:text-first-color">Your email</label>
            <input v-model="formData.email" type="email" id="email"
              class="bg-gray-50 border border-gray-300 text-tenth-color text-sm rounded-lg focus:ring-first-color focus:border-first-color block w-full p-3 dark:bg-tenth-color dark:border-first-color dark:placeholder-fourth-color dark:text-first-color dark:focus:ring-first-color dark:focus:border-first-color"
              placeholder="name@flowbite.com" required>
          </div>
          <div>
            <label for="phone" class="block mb-2 text-sm font-medium text-first-color dark:text-first-color">Phone
              Number</label>
            <input v-model="formData.phone" type="number" id="phone"
              class="block w-full p-3 text-sm text-tenth-color border border-gray-300 rounded-lg bg-gray-50 focus:ring-first-color focus:border-first-color dark:bg-tenth-color dark:border-first-color dark:placeholder-fourth-color dark:text-first-color dark:focus:ring-first-color dark:focus:border-first-color"
              placeholder="+12 345 6789" required>
          </div>
          <div class="sm:col-span-2">
            <label for="message" class="block mb-2 text-sm font-medium text-first-color dark:text-first-color">Your
              message</label>
            <textarea v-model="formData.message" id="message" rows="6"
              class="block p-2.5 w-full text-sm text-tenth-color bg-gray-50 rounded-lg border border-gray-300 focus:ring-first-color focus:border-first-color dark:bg-tenth-color dark:border-first-color dark:placeholder-fourth-color dark:text-first-color dark:focus:ring-first-color dark:focus:border-first-color"
              placeholder="Leave a comment..."></textarea>
              <div class="flex mt-4">
                <input v-model="formData.checkbox" id="checkbox" type="checkbox" value=""
                  class="w-4 h-4 mt-0.5 text-tenth-color bg-first-color border-first-color rounded focus:ring-first-color dark:focus:ring-first-color dark:ring-offset-first-color focus:ring-2 dark:bg-tenth-color dark:border-first-color">
                <label for="checkbox" class="ml-2 text-sm text-first-color dark:text-first-color">I confirm that I have read and agree to our <a
                    class="font-normal text-first-color underline hover:no-underline dark:text-first-color" href="/terms">Terms of
                    Service</a> and <a class="font-normal text-first-color underline hover:no-underline dark:text-first-color"
                    href="/privacy-policy">Privacy Statement</a>.</label>
              </div>
          </div>
          <button type="submit"
            class="py-2.5 px-5 text-sm font-medium text-center text-first-color rounded-lg bg-tenth-color sm:w-fit hover:bg-eighth-color focus:ring-4 focus:outline-none focus:ring-first-color dark:bg-tenth-color dark:hover:bg-eighth-color dark:focus:ring-first-color border border-first-color" :disabled="loading">Send
            message</button>
        </form>
    
        <div class="space-y-8 text-center md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 lg:gap-16 md:space-y-0">
          <div>
            <div
              class="flex items-center justify-center w-10 h-10 mx-auto mb-4 bg-tenth-color rounded-lg dark:bg-tenth-color lg:h-16 lg:w-16">
              <svg class="w-5 h-5 text-first-color lg:w-8 lg:h-8 dark:text-first-color" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </div>
            <p class="mb-2 text-xl font-bold dark:text-first-color">Email us:</p>
            <p class="mb-3 text-first-color dark:text-first-color">Email us for general queries, including marketing and
              partnership opportunities.</p>
            <a href="mailto:contact@joiners.io"
              class="font-semibold text-first-color dark:text-first-color hover:underline">contact@joiner.io</a>
          </div>
          <div>
            <div
              class="flex items-center justify-center w-10 h-10 mx-auto mb-4 bg-tenth-color rounded-lg dark:bg-tenth-color lg:h-16 lg:w-16">
              <svg class="w-5 h-5 text-first-color lg:w-8 lg:h-8 dark:text-first-color" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z">
                </path>
              </svg>
            </div>
            <p class="mb-2 text-xl font-bold dark:text-first-color">Call us:</p>
            <p class="mb-3 text-first-color dark:text-first-color">Call us to speak to a member of our team. We are always happy
              to help.</p>
            <span class="font-semibold text-first-color dark:text-first-color">+33 682 125 869</span>
          </div>
          <div>
            <div
              class="flex items-center justify-center w-10 h-10 mx-auto mb-4 bg-tenth-color rounded-lg dark:bg-tenth-color lg:h-16 lg:w-16">
              <svg class="w-5 h-5 text-first-color lg:w-8 lg:h-8 dark:text-first-color" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <p class="mb-2 text-xl font-bold dark:text-first-color">Support</p>
            <p class="mb-3 text-first-color dark:text-first-color">Email us for general queries, including marketing and
              partnership opportunities.</p>
            <a href="mailto:support@joiners.io?subject=Thank%20you%20for%20letting%20us%20know%20the%20subject%20of%20your%20request.&body=Please%20provide%20us%20with%20more%20details%20about%20your%20issue%2C%20and%20we%20will%20get%20back%20to%20you%20as%20soon%20as%20possible%20within%2024%20hours%2C%20excluding%20weekends%20and%20holidays."
              class="inline-flex px-4 py-2 text-sm font-medium text-center border rounded-lg text-first-color border-first-color hover:text-first-color hover:bg-eighth-color focus:ring-4 focus:outline-none focus:ring-first-color dark:border-first-color dark:text-first-color dark:hover:text-first-color dark:hover:bg-eighth-color dark:focus:ring-first-color">
              Support Email</a>
          </div>
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none" aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
          </div>
      </div>
    </section>
    
    <section class="bg-tenth-color dark:bg-tenth-color">
      <div class="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24">
        <div class="max-w-2xl mx-auto text-center">
          <h2 class="text-3xl font-extrabold tracking-tight text-first-color sm:text-4xl dark:text-first-color">
            Get started with Joiners today
          </h2>
          <p class="mt-4 text-base text-first-color md:text-lg dark:text-first-color">
            Start managing arrivals and departures within your SaaS ecosystem effectively today. 
          </p>
          <a href="/signin"
              class="inline-flex mt-4 px-4 py-2 text-sm font-medium text-center border rounded-lg text-first-color border-first-color hover:text-first-color hover:bg-eighth-color focus:ring-4 focus:outline-none focus:ring-first-color dark:border-first-color dark:text-first-color dark:hover:text-first-color dark:hover:bg-eighth-color dark:focus:ring-first-color">
              Create an account</a>
        </div>
      </div>
    </section>
     <site-footer></site-footer>
    </template>
    
    <script>
    import message from "@/services/message";
    import SiteNavBar from "@/components/SiteNavBar";
    import SiteFooter from "@/components/SiteFooter";
    
    export default {
        components: {
       SiteNavBar,
       SiteFooter
     },
        data() {
            return {
                formData: {
                    firstname: "",
                    lastname: "",
                    phone: "",
                    email: "",
                    subject: "",
                    message: "",
                    optin: ""
                },
                loading: false,
            };
        },
        methods: {
            async submitForm() {
                this.loading = true;
                try {
                    const response = await message.create(this.formData);
                    this.$router.push('/');
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loading = false;
                }
            },
        },
    };
    </script>
    
    <style scoped>
    .form-width {
        max-width: 800px;
        /* Adjust the value as needed */
    }
    </style>