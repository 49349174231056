import apiClient from './apiClient';

export default {
  async index(query = '', page = 1) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/payments`, {
        params: {
          page: page,
          per_page: 3,
          query: query
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};