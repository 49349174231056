<template>
  <div
    class="flex flex-col items-start space-y-4 p-4 bg-first-color border-b border-third-color dark:bg-first-color dark:border-third-color text-sm"
  >
    <span class="font-medium text-tenth-color dark:text-tenth-color"
      >Select only:</span
    >
    <div class="flex items-center space-x-4">
      <label class="inline-flex items-center">
        <input
          type="radio"
          value="all-creation"
          v-model="selectedFilter"
          class="form-radio text-tenth-color border-tenth-color"
        />
        <span class="ml-2 text-tenth-color dark:text-tenth-color"
          >Available for creation</span
        >
      </label>
      <label class="inline-flex items-center">
        <input
          type="radio"
          value="all-deletion"
          v-model="selectedFilter"
          class="form-radio text-tenth-color border-tenth-color"
        />
        <span class="ml-2 text-tenth-color dark:text-tenth-color"
          >Available for deletion</span
        >
      </label>
      <span
        v-if="hasItemsSelected"
        class="ml-10 text-tenth-color dark:text-tenth-color underline cursor-pointer"
        @click="clearSelection"
        >Clear selection</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasItemsSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedFilter: "", // Ensure no filter is selected by default
    };
  },
  watch: {
    selectedFilter(newValue) {
      this.$emit("filter-changed", newValue);
    },
  },
  methods: {
    clearSelection() {
      this.selectedFilter = "";
      this.$emit("clear-selection");
    },
  },
};
</script>
