<template>
  <div
    class="credential-card border rounded-lg p-4 mb-4 flex justify-between items-center"
  >
    <div class="flex items-center">
      <div>
        <h3 class="text-lg font-semibold text-left">{{ credential.name }}</h3>
        <p class="text-sm text-gray-500 text-left">
          {{ credential.description || "No description" }}
        </p>
      </div>
    </div>
    <div class="text-right">
      <p class="text-sm text-gray-400">Created {{ formattedDate }}</p>
      <div class="mt-2">
        <a href="#" class="text-blue-500 mr-4" @click.prevent="showTokenModal"
          >View credentials</a
        >
        <!-- Add the delete button here -->
        <a href="#" class="text-red-500" @click.prevent="deleteCredential"
          >Delete</a
        >
      </div>
    </div>

    <!-- Token View Modal -->
    <TokenViewModal
      v-if="isModalVisible"
      :access-token="credential['access-token']"
      :uid="credential.uid"
      :client="credential.client"
      :showModal="isModalVisible"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import TokenViewModal from "../components/TokenViewModal.vue";
import credential from "@/services/credential";

export default {
  components: {
    TokenViewModal,
  },
  props: {
    credential: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    formattedDate() {
      const date = new Date(this.credential.created_at);
      return date.toLocaleDateString("en-EN", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {
    showTokenModal() {
      this.isModalVisible = true;
    },
    deleteCredential() {
      if (confirm("Are you sure you want to delete this credential?")) {
        credential
          .destroy(this.credential.client)
          .then(() => {
            this.$emit("credential-deleted", this.credential.client);
            window.location.reload();
          })
          .catch((error) => {
            console.error("Failed to delete credential:", error);
          });
      }
    },
  },
};
</script>
