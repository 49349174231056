import apiClient from './apiClient';

export default {
  async show() {
    try {
      const response = await apiClient.get(
        `api/v1/platform_users/subscription`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
