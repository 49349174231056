<template>
  <button
    data-drawer-target="separator-sidebar"
    data-drawer-toggle="separator-sidebar"
    aria-controls="separator-sidebar"
    type="button"
    class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-fourth-color rounded-lg sm:hidden hover:bg-first-color focus:outline-none focus:ring-2 focus:ring-first-color dark:text-fourth-color dark:hover:bg-ninth-color dark:focus:ring-ninth-color"
  >
    <span class="sr-only">Open sidebar</span>
    <svg
      class="w-6 h-6"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        fill-rule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
      ></path>
    </svg>
  </button>

  <aside
    id="separator-sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar"
  >
    <div
      class="h-full flex flex-col px-3 py-4 overflow-y-auto bg-eleventh-color dark:bg-eleventh-color"
    >
      <ul class="space-y-2 font-medium text-first-color">
        <li>
          <a
            href="/"
            class="flex items-center space-x-3 rtl:space-x-reverse pb-6"
          >
            <img
              src="/img/icons/white_logo.png"
              class="h-8"
              alt="Flowbite Logo"
            />
            <span
              class="self-center text-2xl font-semibold first-colorspace-nowrap dark:text-first-color"
              >Joiners</span
            >
          </a>
        </li>
        <div>
          <a
            href="/users"
            class="flex items-center p-2 mb-2 text-first-color rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="1"
                d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <span class="ms-3">Users</span>
          </a>

          <a
            href="/tools"
            class="flex items-center p-2 mb-2 text-first-color rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
              />
            </svg>
            <span class="ms-3">Tools</span>
          </a>
          <a
            href="market-place"
            class="flex items-center p-2 mb-2 text-first-color rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M14 17h6m-3 3v-6M4.857 4h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857H4.857A.857.857 0 0 1 4 9.143V4.857C4 4.384 4.384 4 4.857 4Zm10 0h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857h-4.286A.857.857 0 0 1 14 9.143V4.857c0-.473.384-.857.857-.857Zm-10 10h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857H4.857A.857.857 0 0 1 4 19.143v-4.286c0-.473.384-.857.857-.857Z"
              />
            </svg>
            <span class="ms-3">Marketplace</span>
          </a>
          <a
            href="/api"
            class="flex items-center p-2 mb-2 text-first-color rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M12 20h9m-9 0-3-3m3 3-3 3m0-16v9m0-9-3 3m3-3 3 3m-9 3H4m9 0 3 3m-3-3 3-3"
              />
            </svg>
            <span class="ms-3">API</span>
          </a>
          <a
            href="/billing"
            class="flex items-center p-2 mb-2 text-first-color rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M3 10h18M6 14h2m3 0h5M3 7v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1Z"
              />
            </svg>
            <span class="ms-3">Billing</span>
          </a>
        </div>
      </ul>
      <ul
        class="mt-auto pt-4 space-y-2 font-medium border-t border-ninth-color text-first-color"
      >
        <li>
          <a
            href="#"
            @click.prevent="logout"
            class="flex items-center p-2 text-first-color transition duration-75 rounded-lg hover:bg-ninth-color group"
          >
            <svg
              class="w-6 h-6 dark:text-first-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"
              />
            </svg>
            <p class="ms-3 text-left inline">
              Log out<span class="text-xs"> ({{ user.email }})</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import token from "@/services/users/token";

export default {
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  },
  created() {
    const tokens = token.getTokens();
    this.user.firstName = tokens.firstName;
    this.user.lastName = tokens.lastName;
    this.user.email = tokens.email;
  },
  methods: {
    logout() {
      token.clearTokens();
      this.$router.push("/");
    },
  },
};
</script>
