<template>
  <div class="progress-container">
    <div class="progress-header">
      <span>API Usage</span>
      <span>{{ currentCalls }}/{{ totalCalls }} calls</span>
      <span>{{ progress }} %</span>
    </div>
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: progress + '%' }"></div>
    </div>
    <div class="progress-footer">
      <span>Reset API calls count at midnight</span>
      <span>Last updated: {{ new Date().toLocaleTimeString() }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100;
      },
    },
    currentCalls: {
      type: Number,
      required: true,
    },
    totalCalls: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress-container {
  border: 1px solid #e0e0e0; /* Border to mimic the box */
  padding: 12px;
  border-radius: 8px; /* Rounded corners */
  font-family: Arial, sans-serif;
  background-color: #fff;
  max-width: 600px; /* Adjust width as needed */
}

.progress-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: bold;
  color: #344563; /* Dark grey color */
}

.progress-bar {
  width: 100%;
  height: 12px;
  background-color: #e8edf4; /* Light grey background for the bar */
  border-radius: 6px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4c9aff; /* Light blue color for the progress */
  transition: width 0.3s ease;
}

.progress-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  color: #7a869a; /* Lighter grey color */
}
</style>
