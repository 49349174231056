import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default {
  async signUp(formData) {
    try {
      const response = await axios.post(`${API_URL}auth/`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async signIn(formData) {
    try {
      const response = await axios.post(`${API_URL}auth/sign_in/`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  },

  payload(data) {
    return {
      ...(data.firstName ? { first_name: data.firstName } : {}),
      ...(data.lastName ? { last_name: data.lastName } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.password ? { password: data.password } : {}),
      ...(data.confirmPassword ? { password_confirmation: data.confirmPassword } : {}),
    };
  },
};