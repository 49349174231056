<template>
  <header>
      <site-nav-bar></site-nav-bar>
  </header>
  <section class="bg-tenth-color dark:bg-tenth-color relative isolate px-6 pt-14 lg:px-8 text-first-color flex flex-col items-center">
    <div class="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
          <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none" aria-hidden="true">
            <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
          </div>
      <div class="flex flex-col gap-12 lg:flex-row lg:items-start xl:gap-20">
        <div class="w-full lg:max-w-xs lg:sticky lg:top-20">
          <div class="border border-first-color rounded-lg bg-tenth-color dark:bg-tenth-color">
            <div class="p-6 space-y-6 lg:p-8">
              <h2 class="text-base font-bold text-first-color uppercase dark:text-first-color">
                Legal
              </h2>
  
              <ul class="space-y-4">
                <li>
                  <a href="#general-information" title="" class="text-left font-medium text-first-color dark:text-first-color">
                    General Information
                  </a>
                </li>
  
                <li>
                  <a href="#data-collected" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Types of Data Collected
                  </a>
                </li>
  
                <li>
                  <a href="#use-of-data" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Use of Data
                  </a>
                </li>
  
                <li>
                  <a href="#legal-basis" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Legal Basis for Processing
                  </a>
                </li>
  
                <li>
                  <a href="#user-rights" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Users' Rights
                  </a>
                </li>
  
                <li>
                  <a href="#data-security" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Data Security
                  </a>
                </li>
  
                <li>
                  <a href="#cookies" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Cookies and Similar Technologies
                  </a>
                </li>
  
                <li>
                  <a href="#changes-policy" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Changes to the Privacy Policy
                  </a>
                </li>
  
                <li>
                  <a href="#contact" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Contact
                  </a>
                </li>
              </ul>
  
              <a href="/contact"
            class="inline-flex mt-4 px-4 py-2 text-sm font-medium text-center border rounded-lg text-first-color border-first-color hover:text-first-color hover:bg-eighth-color focus:ring-4 focus:outline-none focus:ring-first-color dark:border-first-color dark:text-first-color dark:hover:text-first-color dark:hover:bg-eighth-color dark:focus:ring-first-color">
            Got a question? Contact Us</a>
            </div>
          </div>
        </div>
  
        <div class="flex-1 min-w-0">
          <h3 id="general-information" class="text-2xl text-left font-bold text-first-color dark:text-first-color">
            General Information
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            <strong>Company Name:</strong> Joiners <br>
            <strong>Address:</strong> 53 avenue du maréchal Foch, 78400 Chatou <br>
            <strong>Website:</strong> <a href="https://www.joiner.io" class="text-first-color dark:text-first-color">joiner.io</a> <br>
            <strong>Data Protection Officer:</strong> Xavier HOPE BRIDARD (Email: <a href="mailto:xavier@joiners.io" class="text-first-color dark:text-first-color">xavier@joiners.io</a>)
          </p>
  
          <h3 id="data-collected" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Types of Data Collected
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            We collect the following personal data:
          </p>
          <ul class="mt-4 text-left space-y-2 text-first-color list-disc list-inside dark:text-first-color">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>IP address</li>
            <li>Payment data</li>
          </ul>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            <strong>Sources of data:</strong> Forms on our website, Cookies, Third-party platforms (HubSpot, Stripe, Heroku, Google Analytics, Google Tag Manager, Zapier).
          </p>
  
          <h3 id="use-of-data" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Use of Data
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            The personal data collected is used for the following purposes:
          </p>
          <ul class="mt-4 text-left space-y-2 text-first-color list-disc list-inside dark:text-first-color">
            <li>Marketing: To inform you about our services, special offers, and news.</li>
            <li>Service Improvement: To analyze and improve the quality of our services.</li>
            <li>Legal Obligations: To comply with our legal and regulatory obligations.</li>
          </ul>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            <strong>Data Sharing:</strong> We share data with the following software solutions as part of data processing within Joiners: HubSpot, Slack, Heroku, Zapier, Google Analytics.
          </p>
  
          <h3 id="legal-basis" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Legal Basis for Processing
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            The processing of your personal data is based on the following legal grounds:
          </p>
          <ul class="mt-4 text-left space-y-2 text-first-color list-disc list-inside dark:text-first-color">
            <li>Consent: Processing is based on the explicit consent of users.</li>
            <li>Contract: Processing of data such as payments, email, name, first name, phone number, and the name and address of the client company is necessary for the performance of the contract.</li>
            <li>Legal Obligation: We collect certain data to comply with legal obligations.</li>
          </ul>
  
          <h3 id="user-rights" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Users' Rights
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            You have the following rights regarding your personal data:
          </p>
          <ul class="mt-4 text-left space-y-2 text-first-color list-disc list-inside dark:text-first-color">
            <li>Access and Rectification: You can access your personal data and correct it if it is inaccurate.</li>
            <li>Deletion: You can request the deletion of your personal data.</li>
            <li>Data Portability: You have the right to receive a structured copy of your personal data.</li>
            <li>Right to Object: You can object to the processing of your personal data for legitimate reasons.</li>
          </ul>
  
          <h3 id="data-security" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Data Security
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            We take the security of your data very seriously. Data management is outsourced to partners such as Heroku, HubSpot, Google Analytics, who adhere to high standards of data security and protection.
          </p>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            <strong>Data Retention Period:</strong> Your personal data is retained for 5 years after your last interaction with our services.
          </p>
  
          <h3 id="cookies" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Cookies and Similar Technologies
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            We use the following types of cookies on our website:
          </p>
          <ul class="mt-4 text-left space-y-2 text-first-color list-disc list-inside dark:text-first-color">
            <li>Technical cookies</li>
            <li>Analytical cookies</li>
            <li>Advertising cookies</li>
          </ul>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            <strong>Consent Banner:</strong> On your first visit to our website, a cookie consent banner allows you to manage your preferences.
          </p>
  
          <h3 id="changes-policy" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Changes to the Privacy Policy
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            We may update this privacy policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make changes, we will notify you by email.
          </p>
  
          <h3 id="contact" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Contact
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            For any questions or requests regarding this privacy policy, you can contact us at: <a href="mailto:contact@joiners.io" class="text-first-color dark:text-first-color">contact@joiners.io</a>.
          </p>
        </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
        </div>
    </div>
  </section>
  
  <site-footer></site-footer>
  </template>
  
  <script>
  import SiteNavBar from "@/components/SiteNavBar";
  import SiteFooter from "@/components/SiteFooter";
  
  export default {
   components: {
     SiteNavBar,
     SiteFooter
   }
  }
  </script>