<template>
  <div
    v-if="showModal"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <!-- Backdrop -->
    <div class="bg-black opacity-70 absolute inset-0"></div>
    <!-- Modal Content -->
    <div
      class="bg-white rounded-lg shadow-lg p-6 relative max-w-md w-full z-10"
    >
      <!-- Close Button -->
      <button @click="closeModal" class="absolute top-0 right-0 m-2">
        <svg
          class="h-6 w-6 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <h3 class="text-xl font-semibold mb-4">Credentials</h3>
      <div class="break-all">
        <p class="bg-gray-100 p-2 rounded mb-4 flex justify-between">
          <span>
            <strong>Access Token:</strong> {{ accessToken }}
          </span>
          <button @click="copyToken(accessToken)" class="text-blue-500">Copy</button>
        </p>
        <p class="bg-gray-100 p-2 rounded mb-4 flex justify-between">
          <span>
            <strong>UID:</strong> {{ uid }}
          </span>
          <button @click="copyToken(uid)" class="text-blue-500">Copy</button>
        </p>
        <p class="bg-gray-100 p-2 rounded mb-4 flex justify-between">
          <span>
            <strong>Client:</strong> {{ client }}
          </span>
          <button @click="copyToken(client)" class="text-blue-500">Copy</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    accessToken: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    copyToken(value) {
      navigator.clipboard.writeText(value);
      alert(`${value} copied to clipboard!`);
    },
  },
};
</script>

<style scoped>
.bg-white {
  background-color: #ffffff;
}

.bg-black.opacity-70 {
  opacity: 0.7;
}

.break-all {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.truncate-text {
  max-width: 80%;
  word-break: break-all;
}
</style>
