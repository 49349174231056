import axios from 'axios';
import jsyaml from "js-yaml";

const API_URL = process.env.VUE_APP_API_URL;

async function translateFormData(formData) {
  const response = await axios.get("/translations.yml");
  const translations = jsyaml.load(response.data);
  const payload = translations.payload;

  return Object.keys(formData).reduce((result, key) => {
    const newKey = payload[key];
    result[newKey] = formData[key];
    return result;
  }, {});
}

async function postOtp(endpoint, formData) {
  const translatedFormData = await translateFormData(formData);
  return axios.post(`${API_URL}api/v1/${endpoint}`, { otp_verification: translatedFormData });
}

export default {
  async verify_otp(formData) {
    try {
      return await postOtp('verify_otp', formData);
    } catch (error) {
      throw error;
    }
  },

  async send_otp(formData) {
    try {
      return await postOtp('send_otp', formData);
    } catch (error) {
      throw error;
    }
  },
};