<template>
  <div
    v-if="showModal"
    id="crud-modal"
    tabindex="-1"
    class="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full mx-auto">
      <div
        class="relative bg-tenth-color rounded-lg shadow dark:bg-tenth-color"
      >
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-first-color"
        >
          <h3 class="text-lg text-first-color dark:text-first-color">
            Create credentials
          </h3>
          <button
            type="button"
            class="text-first-color bg-transparent hover:bg-tenth-color hover:text-first-color rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-tenth-color dark:hover:text-first-color"
            @click="closeModal"
          >
            <svg
              class="w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form class="p-4" @submit.prevent="submitForm">
          <div>
            <label for="name" class="labels">Name</label>
            <input
              v-model="formData.name"
              type="text"
              name="name"
              id="name"
              class="inputs"
              placeholder="Name"
              required
            />
          </div>
          <div>
            <label for="description" class="labels">Description</label>
            <textarea
              v-model="formData.description"
              name="description"
              id="description"
              class="inputs"
              placeholder="Description"
              rows="4"
            ></textarea>
          </div>
          <div v-if="errorMessage" class="text-white-500 mt-2">
            {{ errorMessage }}
          </div>
          <button type="submit" class="btn-modal-user">Ok !</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import credential from "@/services/credential";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        description: "",
      },
      errorMessage: "", // Add this to store the error message
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await credential.create(this.formData);
        this.$emit("credentials-created", response.data);
        this.closeModal();
        // Reload the page when creation is successful
        window.location.reload();
      } catch (error) {
        this.errorMessage = error.response.data.errors[0];
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
