import axios from "axios";
import jsyaml from "js-yaml";
import apiClient from './apiClient';

export default {
  async authorizationLink(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});
      const createResponse = await apiClient.post(
        `api/v1/platform_users/oauth_connections/authorization_link`,
        { oauth_connection: translatedFormData }
      );

      return createResponse;
    } catch (error) {
      throw error;
    }
  },
  async tokens(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});
      const createResponse = await apiClient.post(
        `api/v1/platform_users/oauth_connections/tokens`,
        { oauth_connection: translatedFormData });

      return createResponse;
    } catch (error) {
      throw error;
    }
  },
};
