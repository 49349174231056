<template>
  <header>
      <site-nav-bar></site-nav-bar>
  </header>
  <section class="bg-tenth-color dark:bg-tenth-color relative isolate px-6 pt-14 lg:px-8 text-first-color flex flex-col items-center">
    <div class="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
          <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none" aria-hidden="true">
            <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
          </div>
      <div class="flex flex-col gap-12 lg:flex-row lg:items-start xl:gap-20">
        <div class="w-full lg:max-w-xs lg:sticky lg:top-20">
          <div class="border border-first-color rounded-lg bg-tenth-color dark:bg-tenth-color">
            <div class="p-6 space-y-6 lg:p-8">
              <h2 class="text-base font-bold text-first-color uppercase dark:text-first-color">
                Legal
              </h2>
  
              <ul class="space-y-4">
                <li>
                  <a href="#scope-of-application" title="" class="text-left font-medium text-first-color dark:text-first-color">
                    Scope of Application
                  </a>
                </li>
  
                <li>
                  <a href="#contract-conclusion" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Contract Conclusion and Trial Period
                  </a>
                </li>
  
                <li>
                  <a href="#services-and-versions" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Services and Versions
                  </a>
                </li>
  
                <li>
                  <a href="#availability-and-maintenance" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Availability and Maintenance
                  </a>
                </li>
  
                <li>
                  <a href="#client-responsibilities" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Client Responsibilities
                  </a>
                </li>
  
                <li>
                  <a href="#limitation-of-liability" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Limitation of Liability
                  </a>
                </li>
  
                <li>
                  <a href="#data-protection" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Data Protection and Confidentiality
                  </a>
                </li>
  
                <li>
                  <a href="#amendments-to-terms" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Amendments to the Terms
                  </a>
                </li>
  
                <li>
                  <a href="#final-provisions" title=""
                    class="text-base text-left font-medium text-first-color hover:text-first-color dark:text-first-color dark:hover:text-fifth-color">
                    Final Provisions
                  </a>
                </li>
              </ul>
  
              <a href="/contact"
            class="inline-flex mt-4 px-4 py-2 text-sm font-medium text-center border rounded-lg text-first-color border-first-color hover:text-first-color hover:bg-eighth-color focus:ring-4 focus:outline-none focus:ring-first-color dark:border-first-color dark:text-first-color dark:hover:text-first-color dark:hover:bg-eighth-color dark:focus:ring-first-color">
            Got a question? Contact Us</a>
            </div>
          </div>
        </div>
  
        <div class="flex-1 min-w-0">
          <h3 id="scope-of-application" class="text-2xl text-left font-bold text-first-color dark:text-first-color">
            Scope of Application
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            1.1 Joiners (hereinafter "Joiners") offers an access management service for administering employee access to third-party SaaS software via its platform.<br>
            1.2 These General Terms and Conditions (GTC) apply to contracts between Joiners and business clients, excluding any other terms not expressly accepted in writing.
          </p>
  
          <h3 id="contract-conclusion" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Contract Conclusion and Trial Period
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            2.1 Access to the Joiners software provided free of charge for 7 days does not constitute a binding contractual offer.<br>
            2.2 At the end of the trial period, the client's account is blocked. A paid contract can be subscribed to at any time before or after the end of this period.
          </p>
  
          <h3 id="services-and-versions" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Services and Versions
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            3.1 During the contract period, Joiners provides SaaS access to the selected version of the software. The available features depend on the subscribed version.<br>
            3.2 The client can switch between software versions, with the change taking effect immediately after the access update.
          </p>
  
          <h3 id="availability-and-maintenance" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Availability and Maintenance
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            4.1 Joiners strives to ensure 99% availability on an annual average, excluding planned maintenance or technical issues beyond its control.<br>
            4.2 Maintenance is carried out outside of business hours whenever possible, with prior notification to the client in case of prolonged disruption.
          </p>
  
          <h3 id="client-responsibilities" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Client Responsibilities
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            5.1 The client is responsible for verifying the software's functionality during the trial period and reporting any issues before subscribing to a paid contract.<br>
            5.2 The client must designate a primary contact for account management and immediately report any security issues or misuse.<br>
            5.3 The client is responsible for the technical setup and administration of their account.
          </p>
  
          <h3 id="limitation-of-liability" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Limitation of Liability
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            6.1 For paid services, Joiners is liable for damages in accordance with applicable legal provisions. Liability is limited to foreseeable damages in cases of slight breaches of essential contractual obligations.<br>
            6.2 For free services, Joiners is liable only for damages caused intentionally or by gross negligence.
          </p>
  
          <h3 id="data-protection" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Data Protection and Confidentiality
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            7.1 Joiners processes personal data in accordance with applicable laws.<br>
            7.2 Both parties agree not to disclose confidential information received under the contract, except where legally required.
          </p>
  
          <h3 id="amendments-to-terms" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Amendments to the Terms
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            8.1 Joiners reserves the right to modify these GTC at any time. Clients will be notified in writing of any changes, which will be deemed accepted unless a written objection is made within two weeks.<br>
            8.2 Joiners may adjust prices by providing prior notice to the client. Price adjustments do not apply retroactively to periods already paid for.
          </p>
  
          <h3 id="final-provisions" class="mt-8 text-2xl text-left font-bold text-first-color dark:text-first-color">
            Final Provisions
          </h3>
          <p class="mt-4 text-base text-left font-normal text-first-color dark:text-first-color">
            9.1 If any provision of these GTC is deemed invalid, the remaining provisions remain in effect.<br>
            9.2 The contract is governed by the law of France, excluding international conventions. Any disputes will be submitted to the competent courts at Joiners’s registered office.
          </p>
        </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
        </div>
    </div>
  </section>
  
  <site-footer></site-footer>
  </template>
  
  <script>
  import SiteNavBar from "@/components/SiteNavBar";
  import SiteFooter from "@/components/SiteFooter";
  
  export default {
   components: {
     SiteNavBar,
     SiteFooter
   }
  }
  </script>
  