<template>
  <div
    v-if="showModal"
    id="crud-modal"
    tabindex="-1"
    class="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full mx-auto">
      <div class="relative bg-tenth-color rounded-lg shadow dark:bg-tenth-color">
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-first-color"
        >
          <h3 class="text-lg text-first-color dark:text-first-color">
            Create a user
          </h3>
          <button
            type="button"
            class="text-first-color bg-transparent hover:bg-tenth-color hover:text-first-color rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-tenth-color dark:hover:text-first-color"
            data-modal-toggle="crud-modal"
            @click="closeModal"
          >
            <svg
              class="w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form class="p-4 md:p-5" @submit.prevent="submitForm">
          <div>
            <label
              for="email"
              class="labels"
              >Email</label
            >
            <input
              v-model="formData.email"
              type="email"
              name="email"
              id="email"
              class="inputs"
              placeholder="Email"
              required
            />
          </div>
          <p v-if="emailError" class="mt-2 text-sm text-red-color">
            <span class="font-medium">Oops!</span> {{ emailError }}
          </p>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label
                for="lastName"
                class="labels"
                >Last name</label
              >
              <input
                v-model="formData.lastName"
                type="text"
                name="lastName"
                id="lastName"
                class="inputs"
                placeholder="Last name"
                required
              />
            </div>
            <div>
              <label
                for="firstName"
                class="labels"
                >First name</label
              >
              <input
                v-model="formData.firstName"
                type="text"
                name="firstName"
                id="firstName"
                class="inputs"
                placeholder="First name"
                required
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label
                for="phoneNumber"
                class="labels"
                >Phone number</label
              >
              <input
                v-model="formData.phoneNumber"
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                class="inputs"
                placeholder="Phone number"
                required
              />
            </div>
            <di>
              <label
                for="JobTitle"
                class="labels"
                >Job Title</label
              >
              <input
                v-model="formData.jobTitle"
                type="text"
                name="jobTitle"
                id="jobTitle"
                class="inputs"
                placeholder="Job Title"
                required
              />
            </di>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label
                for="role"
                class="labels"
                >Role</label
              >
              <input
                v-model="formData.role"
                type="text"
                name="role"
                id="role"
                class="inputs"
                placeholder="Role"
                required
              />
            </div>
            <div>
              <label
                for="contractType"
                class="labels"
                >Contract Type</label
              >
              <select
                v-model="formData.contractType"
                name="contractType"
                id="contractType"
                class="inputs"
                required
              >
                <option value="Permanent">Permanent</option>
                <option value="Temporary">Temporary</option>
              </select>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label
                for="arrivalDate"
                class="labels"
                >Arrival date</label
              >
              <input
                v-model="formData.arrivalDate"
                type="date"
                name="arrivalDate"
                id="arrivalDate"
                class="inputs"
                placeholder="Arrival date"
                required
              />
              <p v-if="dateError" class="mt-2 text-sm text-red-color">
                <span class="font-medium">Oops!</span> Invalid arrival date
              </p>
            </div>
            <div
              v-if="
                formData.contractType === 'Temporary' ||
                formData.contractType === ''
              "
            >
              <label
                for="departureDate"
                class="labels"
                >Departure date</label
              >
              <input
                v-model="formData.departureDate"
                type="date"
                name="departureDate"
                id="departureDate"
                class="inputs"
                placeholder="Departure date"
                required
              />
              <p v-if="dateError" class="mt-2 text-sm text-red-color">
                <span class="font-medium">Oops!</span> Invalid departure date
              </p>
            </div>
          </div>
          <button
            type="submit"
            class="btn-modal-user"
          >
            Ok !
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/services/user";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      formData: {
        email: "",
        lastName: "",
        firstName: "",
        phoneNumber: "",
        jobTitle: "",
        role: "",
        contractType: "",
        arrivalDate: "",
        departureDate: "",
      },
      showModal: true,
      emailError: "",
      dateError: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        this.formData.contractType = this.formData.contractType.toLowerCase();
        const response = await user.create(this.formData);
        this.$emit("itemCreated");
        this.closeModal();
      } catch (error) {
        if (error.response.data.errors[0].user.email) {
          this.emailError = "Already used email";
        } else if (error.response.data.errors[0].user.arrival_date) {
          this.dateError = "Invalid arrival and departure dates";
        } else {
          console.error(error);
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
