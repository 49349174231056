<template>
  <header>
    <site-nav-bar></site-nav-bar>
  </header>
  <section class="bg-first-color dark:bg-tenth-color ">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 ">
        <div class="grid items-center gap-8 mb-8 lg:mb-16 lg:gap-12 lg:grid-cols-12">
            <div class="col-span-6 text-center sm:mb-6 lg:text-left lg:mb-0">
              <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"  aria-hidden="true">
                <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
              </div>
                <a href="/signin" class="inline-flex items-center justify-between px-1 py-1 pr-4 mb-6 text-sm text-first-color bg-tenth-color rounded-full dark:bg-tenth-color dark:text-first-color hover:bg-tenth-color dark:hover:bg-tenth-color border border-tenth-color dark:border-first-color" role="alert">
                    <span class="px-3 py-1 mr-3 text-xs text-tenth-color rounded-full bg-first-color">New</span> <span class="text-sm font-medium">Integrations available</span> 
                    <svg class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a 1 1 0 011.414-1.414l4 4a 1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                </a>
                <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-first-color md:text-5xl xl:text-6xl dark:text-first-color">Easier Onboarding & Outboarding</h1>
                <p class="max-w-xl mx-auto mb-6 font-light text-first-color lg:mx-0 xl:mb-8 md:text-lg xl:text-xl dark:text-first-color">Finaly a solution to create, manage and delete user without any SSO required</p>
                <div>
                <a href="/signin" title="" class="btn cursor-pointer" role="button">See integrations</a>
              </div>
            </div>
            <div class="col-span-6 relative">
                <div class="absolute inset-0 z-0 flex items-center justify-center opacity-5 pointer-events-none">
                  <logo-animated></logo-animated>
                </div>
                <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/search-mockup.png" class="relative z-10 dark:hidden" alt="mockup">
                <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/search-mockup-dark.png" class="relative z-10 hidden dark:block" alt="mockup dark">
            </div>
        </div>
        <div class="grid gap-8 sm:gap-12 md:grid-cols-3">
            <div class="flex justify-center">
                <svg class="w-6 h-6 mr-3 text-first-color dark:text-first-color shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z" clip-rule="evenodd"></path></svg>
                <div>
                    <h3 class="mb-1 text-lg font-semibold leading-tight text-first-color dark:text-first-color">Pre-configuring roles</h3>
                    <p class="font-light text-first-color dark:text-first-color">Customize predefined roles to quickly grant access to your SaaS solutions.</p>
                </div>
            </div>
            <div class="flex justify-center">
                <svg class="w-6 h-6 mr-3 text-first-color dark:text-first-color shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
                <div>
                    <h3 class="mb-1 text-lg font-semibold leading-tight text-first-color dark:first-color">Securing access</h3>
                    <p class="font-light text-first-color dark:text-first-color">Get notified of fraudulent access attempts not linked to your domains.</p>
                </div>
            </div>
            <div class="flex justify-center">
                <svg class="w-6 h-6 mr-3 text-first-color dark:text-first-color shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clip-rule="evenodd"></path></svg>
                <div>
                    <h3 class="mb-1 text-lg font-semibold leading-tight text-first-color dark:first-color">Streamlining your costs</h3>
                    <p class="font-light text-first-color dark:text-first-color">Be notified of contract expirations and alerted for inactivity.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
  <div class="bg-tenth-color min-h-screen flex flex-col justify-center">
    <div class="relative isolate px-6 pt-6 lg:px-8 flex-1 flex items-center justify-center">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
      </div>
      <div class="max-w-screen-xl px-4 mx-auto sm:py-4 lg:py-24 pt-2 sm:pt-4 lg:pt-6 scroll-smooth">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-first-color sm:text-4xl dark:text-first-color">
          How to Streamline User Management
        </h2>
        <p class="mt-4 text-base text-first-color sm:text-xl dark:text-first-color">
          More and more SaaS tools are being used to save time. But what about the ease of use of these solutions? In just 2 minutes, we present to you the benefits of Joiners.
        </p>
      </div>
      <iframe v-intersection-observer="handleIntersect" class="w-full h-64 max-w-2xl mx-auto mt-8 rounded-lg lg:mt-12 sm:h-96 animate-fade-up animate-once animate-duration-6000 animate-delay-1000 animate-ease-out animate-normal animate-fill-both"
        src="https://www.youtube.com/embed/4bnJG2UDr9A" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
      </div>
    </div>
  </div>
 </section>
 <section class="bg-tenth-color dark:bg-tenth-color">
    <div class="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24">
        <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-extrabold tracking-tight text-first-color sm:text-4xl dark:text-first-color">
                Testimonials
            </h2>
            <p class="max-w-2xl mx-auto mt-4 text-first-color lg:mb-16 dark:text-first-color sm:text-xl">
                Explore
                the whole collection
                of open-source web components and elements built with the utility classes from Tailwind
            </p>
        </div>

        <div class="grid gap-6 mt-8 lg:grid-cols-3 sm:mt-12 lg:mt-16">
            <div class="space-y-6">
                <figure class="relative p-6 border-first-color rounded-lg  dark:border-first-color border overflow-hidden">
                  <div class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-xl" aria-hidden="true">
                      <div class="relative left-1/2 aspect-[1155/678] w-[40rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
                  </div>
                <blockquote class="text-sm text-first-color dark:text-first-color relative z-10">
                    <h3 class="text-lg font-semibold text-first-color dark:text-first-color">Solid foundation for any project</h3>
                    <p class="my-4">"This is a very complex and beautiful set of elements. Under the hood it comes with the best things from 2 different worlds: Figma and Tailwind.”</p>
                </blockquote>
                <figcaption class="flex items-center space-x-3 relative z-10">
                    <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="profile picture">
                    <div class="space-y-0.5 font-medium dark:text-first-color">
                    <div>Bonnie Green</div>
                    <div class="text-sm text-first-color dark:text-first-color">CTO at Open AI</div>
                </div>
            </figcaption>
          </figure>
        </div>
        <div class="space-y-6">
                <figure class="relative p-6 border-first-color rounded-lg  dark:border-first-color border overflow-hidden">
                  <div class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-xl" aria-hidden="true">
                      <div class="relative left-1/2 aspect-[1155/678] w-[40rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
                  </div>
                <blockquote class="text-sm text-first-color dark:text-first-color relative z-10">
                    <h3 class="text-lg font-semibold text-first-color dark:text-first-color">Solid foundation for any project</h3>
                    <p class="my-4">"This is a very complex and beautiful set of elements. Under the hood it comes with the best things from 2 different worlds: Figma and Tailwind.”</p>
                </blockquote>
                <figcaption class="flex items-center space-x-3 relative z-10">
                    <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="profile picture">
                    <div class="space-y-0.5 font-medium dark:text-first-color">
                    <div>Bonnie Green</div>
                    <div class="text-sm text-first-color dark:text-first-color">CTO at Open AI</div>
                </div>
            </figcaption>
          </figure>
        </div>
        <div class="space-y-6">
                <figure class="relative p-6 border-first-color rounded-lg  dark:border-first-color border overflow-hidden">
                  <div class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-xl" aria-hidden="true">
                      <div class="relative left-1/2 aspect-[1155/678] w-[40rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
                  </div>
                <blockquote class="text-sm text-first-color dark:text-first-color relative z-10">
                    <h3 class="text-lg font-semibold text-first-color dark:text-first-color">Solid foundation for any project</h3>
                    <p class="my-4">"This is a very complex and beautiful set of elements. Under the hood it comes with the best things from 2 different worlds: Figma and Tailwind.”</p>
                </blockquote>
                <figcaption class="flex items-center space-x-3 relative z-10">
                    <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="profile picture">
                    <div class="space-y-0.5 font-medium dark:text-first-color">
                    <div>Bonnie Green</div>
                    <div class="text-sm text-first-color dark:text-first-color">CTO at Open AI</div>
                </div>
            </figcaption>
          </figure>
        </div>
    </div>
        <div class="mt-8 text-center sm:mt-12 lg:mt-16">
          <a href="#" title="" class="text-first-color border border-gray-200 hover:bg-gray-100 focus:ring-gray-100 hover:text-primary-700 dark:text-first-color dark:border-gray-600 dark:hover:bg-gray-700 dark:bg-gray-800 dark:hover:text-white dark:focus:ring-gray-800 justify-center inline-flex items-center focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center" role="button">
                View more
          </a>
        </div>
    </div>
</section>
<site-footer></site-footer>
</template>

<script>
import SiteNavBar from "@/components/SiteNavBar";
import SiteFooter from "@/components/SiteFooter";
import LogoAnimated from "@/components/LogoAnimated.vue";

export default {
 components: {
   SiteNavBar,
   SiteFooter,
   LogoAnimated
 },
 methods: {
    handleIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        observer.disconnect();
      }
    },
  },
}
</script>
