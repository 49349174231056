<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Black Background Section -->
    <div class="hidden md:flex flex-1 p-5 text-center text-first-color bg-tenth-color relative isolate justify-center items-center">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none" aria-hidden="true">
          <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);"></div>
        </div>
      <div class="flex flex-col justify-center items-center h-screen">
        <MovingText />
      </div>
    </div>

    <!-- White Background Section -->
    <div class="flex-1 p-5 text-center text-tenth-color bg-first-color">
      <div class="flex flex-col justify-center items-center h-screen">
        <SignUpForm />
       </div>
    </div>
  </div>
</template>


<script>
import SignUpForm from '@/components/auth/SignUpForm.vue';
import MovingText from '@/components/util/MovingText.vue';

export default {
  components: {
    SignUpForm,
    MovingText,
  },
};
</script>
