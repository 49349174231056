import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  async index() {
    try {
      const response = await axios.get(`${API_URL}api/v1/plans`);
      return response;
    } catch (error) {
      throw error;
    }
  },
}