<template>
  <sidebar-component></sidebar-component>
  <div class="pr-4 pl-4 pb-4 sm:ml-64">
    <div class="relative pt-10">
      <div class="pb-4 bg-first-color dark:bg-first-color flex items-center justify-between">
        <div class="relative mt-1">
          <label for="table-search" class="sr-only">Search</label>
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-first-color dark:text-tenth-color" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            v-model="searchQuery"
            @input="onSearchInput"
            class="search-bar"
            placeholder="Search for items"
          />
        </div>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-tenth-color dark:text-tenth-color">
          <thead class="text-s text-tenth-color bg-first-color dark:bg-first-color dark:text-tenth-color border-b border-fourth-color">
            <tr>
              <th scope="col" class="px-6 py-3"></th>
              <th
                scope="col"
                class="px-6 py-3"
                v-for="key in attributeKeys"
                :key="key"
              >
                {{ $t(key) }}
              </th>
              <th scope="col" class="px-6 py-3"></th>
              <th scope="col" class="px-6 py-3"></th>
              <th scope="col" class="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in data"
              :key="item.id"
              class="odd:bg-first-color odd:dark:bg-first-color even:bg-first-color even:dark:bg-second-color border-b dark:border-second-color"
            >
              <td class="px-6 py-4">
                <div class="flex items-center justify-center">
                  <template v-if="item.attributes.name === 'Hubspot'">
                    <font-awesome-icon
                      class="fa-3x"
                      icon="fa-brands fa-hubspot"
                      style="color: #fd5d35"
                    />
                  </template>
                  <template v-if="item.attributes.name === 'Slack'">
                    <img
                      class="h-10 w-10"
                      src="https://cdn-icons-png.flaticon.com/512/3800/3800024.png"
                      alt="Slack Logo"
                    />
                  </template>
                  <template v-if="item.attributes.name === 'Github'">
                    <font-awesome-icon
                      class="fa-3x"
                      icon="fa-brands fa-github"
                      style="color: #02040a"
                    />
                  </template>
                  <template v-if="item.attributes.name === 'Google'">
                    <img
                      class="supercloud-logo"
                      alt="Logo Google Cloud"
                      src="https://www.gstatic.com/pantheon/images/welcome/supercloud.svg"
                    />
                  </template>
                  <template v-if="item.attributes.name === 'Ringover'">
                    <svg
                      class="h-10 w-10"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d="M12.7487636,26.0357143 C6.44286601,26.0357143 1.49207336,21.8112333 0.373650926,15.6875264 C0.359732676,15.6113197 0.34019606,15.4963529 0.315041079,15.342626 L6.56344993,15.3427385 C6.5908412,15.4348227 6.61183136,15.5034988 6.62642038,15.5487668 C7.55282415,18.4232847 10.0690497,19.9096639 12.7487636,19.9096639 C15.432939,19.9096639 17.9530813,18.4494006 18.8757208,15.5535215 C18.8904769,15.5072066 18.9116851,15.4369081 18.9393453,15.342626 L25.1824861,15.342626 C25.1615897,15.4711433 25.1454487,15.5673025 25.1340631,15.6311037 C24.0358226,21.7852691 19.0740099,26.0357143 12.7487636,26.0357143 Z M12.7487636,0 C19.3444301,0 24.4575906,4.57963363 25.2535401,11.1938247 C25.264946,11.2886056 25.2800508,11.4317085 25.2988544,11.6231335 L19.142136,11.6231335 C19.1243944,11.5144466 19.1105316,11.4333869 19.1005476,11.3799545 C18.4481121,7.88825231 15.697413,6.12605042 12.7487636,6.12605042 C9.83383355,6.12605042 7.11235238,7.84817927 6.42018275,11.2608445 C6.40413025,11.3399896 6.38253305,11.4607526 6.35539115,11.6231335 L0.198672699,11.6231335 C0.225874504,11.3543914 0.2482484,11.153762 0.265794389,11.0212454 C1.12923201,4.50010485 6.21061717,0 12.7487636,0 Z"
                        fill="#00BD82"
                      ></path>
                    </svg>
                  </template>
                  <template v-if="item.attributes.name === 'Discord'">
                    <font-awesome-icon
                      class="fa-3x"
                      icon="fa-brands fa-discord"
                      style="color: #505acd"
                    />
                  </template>
                  <template v-if="item.attributes.name === 'Aircall'">
                    <svg
                      class="h-10 w-10"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 40 38"
                    >
                      <path
                        d="M30.07.86C27.82.34 24.123 0 19.943 0c-4.18 0-7.878.34-10.127.86C5.33 1.804 1.799 5.346.858 9.844.338 12.1 0 15.808 0 20s.339 7.9.858 10.156c.941 4.498 4.473 8.04 8.958 8.984 2.25.52 5.947.86 10.127.86 4.18 0 7.878-.34 10.127-.86 4.485-.944 8.017-4.486 8.958-8.984.52-2.256.858-5.964.858-10.156s-.339-7.9-.858-10.156c-.941-4.498-4.473-8.04-8.958-8.984z"
                        fill="#00BD82"
                      ></path>
                      <path
                        d="M24.735 30.705a3.023 3.023 0 00-2.16-1.79c-.584-.135-1.546-.223-2.632-.223-1.087 0-2.049.088-2.633.224-.97.204-1.77.876-2.151 1.77-.133.31-.45.499-.786.468a28.05 28.05 0 01-.215-.02c-1.564-.157-2.793-.375-3.507-.629a1.27 1.27 0 01-.816-1.354c.121-1.632 1.11-5.454 2.586-9.816 1.688-4.987 3.454-9.024 4.25-9.811a1.71 1.71 0 01.681-.436c.523-.177 1.487-.296 2.59-.296 1.104 0 2.068.119 2.59.296l.009.003a1.711 1.711 0 01.674.433c.796.787 2.561 4.824 4.249 9.81 1.477 4.363 2.465 8.185 2.587 9.817 0 .013 0 .027.002.041l.002.015a6.196 6.196 0 01.004.108 1.27 1.27 0 01-.824 1.19c-.714.254-1.942.472-3.504.628l-.246.023a.74.74 0 01-.75-.45z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </template>
                  <template v-if="item.attributes.name === 'Microsoft'">
                    <svg
                      class="h-10 w-10"
                      aria-hidden="true"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      itemprop="logo"
                      itemscope="itemscope"
                    >
                      <path
                        d="M11.5216 0.5H0V11.9067H11.5216V0.5Z"
                        fill="#f25022"
                      ></path>
                      <path
                        d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z"
                        fill="#7fba00"
                      ></path>
                      <path
                        d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z"
                        fill="#00a4ef"
                      ></path>
                      <path
                        d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z"
                        fill="#ffb900"
                      ></path>
                    </svg>
                  </template>
                </div>
              </td>
              <td
                class="px-6 py-4 description-column"
                v-for="key in attributeKeys"
                :key="key"
              >
                <span class="bold-text">
                  <span v-if="typeof item.attributes[key] === 'object'">
                    {{ displayObject(item.attributes[key]) }}
                  </span>
                  <span v-else-if="key !== 'connected'">
                    {{ item.attributes[key] }}
                  </span>
                  <div v-else>
                    <div v-if="item.attributes[key]" class="flex items-center">
                      <div class="h-2.5 w-2.5 rounded-full bg-green-color me-2"></div>
                      <span>Online</span>
                    </div>
                    <div v-else class="flex items-center">
                      <div class="h-2.5 w-2.5 rounded-full bg-red-color me-2"></div>
                      <span>Offline</span>
                    </div>
                  </div>
                </span>
              </td>
              <td class="no-text-trim">
                <button
                  class="p-2 inline-flex items-center justify-center hover:bg-first-color dark:hover:bg-first-color rounded-lg relative"
                  @mouseover="showTooltip(item.id)"
                  @mouseleave="hideTooltip(item.id)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-circle-info"
                    bounce
                    size="lg"
                    style="color: #ffb42e"
                  />
                  <div
                    v-if="tooltipVisible[item.id]"
                    class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-6 bg-first-color text-tenth-color border border-tenth-color dark:border-tenth-color text-xs rounded shadow-lg w-60 z-10"
                  >
                    {{ getTooltipText(item.attributes.name) }}
                  </div>
                </button>
              </td>
              <td>
                <button @click="syncPlatformIntegration(item.id)">
                  <font-awesome-icon
                    :spin="syncingIds.includes(item.id)"
                    icon="fa-solid fa-arrows-rotate"
                    style="color: #057fee"
                    v-if="
                      item.isConnected &&
                      !['Hubspot', 'Google', 'Slack'].includes(
                        item.attributes.name
                      )
                    "
                  />
                </button>
              </td>
              <td>
                <button
                  class="btn cursor-pointer"
                  @click="item.isConnected ? confirmDisconnection(item.id) : confirmConnection(item.id)"
                >
                  {{ item.isConnected ? "Disconnect" : "Connect" }}
                </button>
              </td>
            </tr>
            <ConnectIntegrationModal
              v-if="showConnectModal"
              @confirm="connectIntegration"
              @cancel="showConnectModal = false"
            />
            <DisconnectIntegrationModal
              v-if="showDisconnectModal"
              @confirm="disconnectIntegration"
              @cancel="showDisconnectModal = false"
            />
          </tbody>
        </table>
        <Pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @goToPage="goToPage"
          @nextPage="nextPage"
          @prevPage="prevPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from "dompurify";
import platformIntegration from "@/services/platformIntegration";
import oauthConnection from "@/services/oauthConnection";

import Pagination from "@/components/util/Pagination.vue";
import ConnectIntegrationModal from "@/components/tools/ConnectIntegrationModal.vue";
import DisconnectIntegrationModal from "@/components/tools/DisconnectIntegrationModal.vue";
import SidebarComponent from "@/components/util/SidebarComponent.vue";

export default {
  components: {
    Pagination,
    ConnectIntegrationModal,
    DisconnectIntegrationModal,
    SidebarComponent,
  },
  data() {
    return {
      data: [],
      selectedId: null,
      showSelectModal: false,
      showConnectModal: false,
      showDisconnectModal: false,
      syncingIds: [],
      tooltipVisible: [],
      currentPage: 1,
      totalPages: 0,
      searchQuery: "",
    };
  },
  computed: {
    attributeKeys() {
      return this.data.length > 0 ? Object.keys(this.data[0].attributes) : [];
    },
  },
  watch: {
    dataType: {
      handler(newVal) {
        this.fetchData(this.currentPage, this.searchQuery);
      },
      immediate: true,
    },
    searchQuery: {
      handler(newVal) {
        this.fetchData(this.currentPage, newVal);
      },
    },
  },
  methods: {
    onSearchInput() {
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    async syncPlatformIntegration(id) {
      this.syncingIds.push(id);
      const updatePromise = platformIntegration.update(id, { sync: true });
      const timeoutPromise = new Promise((resolve) =>
        setTimeout(resolve, 4000)
      );
      await Promise.all([updatePromise, timeoutPromise]);
      this.syncingIds = this.syncingIds.filter((syncingId) => syncingId !== id);
    },
    getTooltipText(name) {
      if (name !== "Hubspot" && name !== "Google" && name !== "Slack") {
        return `Occasionally, a user created directly in ${name} may not synchronize with Joiners automatically. To resolve this, simply click the 'Sync' button on the right to initiate synchronization manually`;
      }
      if (name === "Hubspot" || name === "Google" || name === "Slack") {
        return `As you can see that the Sync button is disabled for ${name}. This is because ${name} provides real-time data information regarding users`;
      }
    },
    showTooltip(id) {
      this.tooltipVisible[id] = true;
    },
    hideTooltip(id) {
      this.tooltipVisible[id] = false;
    },
    async checkIntegrationExists(integration_id) {
      try {
        const response = await platformIntegration.show(integration_id);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return false;
        }
        throw error;
      }
    },
    async disconnectIntegration() {
      try {
        this.showDisconnectModal = false;
        await platformIntegration.update(this.selectedId, { connected: false });
        const selectedItem = this.data.find(
          (item) => item.id === this.selectedId
        );
        if (selectedItem) {
          selectedItem.isConnected = false;
          selectedItem.attributes.connected = false;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    async connectIntegration() {
      try {
        this.showConnectModal = false;
        const response = await oauthConnection.authorizationLink({
          platform_integration: this.selectedId,
        });
        const sanitizedURL = DOMPurify.sanitize(
          response.data.authorizationLink
        );
        window.location.href = sanitizedURL;
        const selectedItem = this.data.find(
          (item) => item.id === this.selectedId
        );
        if (selectedItem) {
          selectedItem.isConnected = true;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    async createIntegration() {
      try {
        this.showSelectModal = false;
        await platformIntegration.create({ integration: this.selectedId });
        this.$router.push({ name: "dashboard" });
        const selectedItem = this.data.find(
          (item) => item.id === this.selectedId
        );
        if (selectedItem) {
          selectedItem.isSelected = true;
        }
        this.selectedId = null;
      } catch (error) {
        console.error(error);
      }
    },
    confirmConnection(id) {
      this.selectedId = id;
      this.showConnectModal = true;
    },
    confirmDisconnection(id) {
      this.selectedId = id;
      this.showDisconnectModal = true;
    },
    confirmSelect(id) {
      this.selectedId = id;
      this.showSelectModal = true;
    },
    async fetchData(page, query = "") {
      try {
        this.data = [];
        this.totalPages = 0;

        const response = await platformIntegration.index(query, page);
        const data = response.data.data;
        this.totalPages = response.data.meta.total_pages;

        for (let item of data) {
          item.isConnected = item.attributes.connected;
        }

        this.data = data;
      } catch (error) {
        console.error(error);
      }
    },
    displayObject(obj) {
      if (obj && typeof obj === "object") {
        const key = Object.keys(obj).find((key) => key !== "id");
        return obj[key];
      }
      return "";
    },
    goToPage(page) {
      this.currentPage = page;
      this.fetchData(this.currentPage, this.searchQuery);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData(this.currentPage, this.searchQuery);
      }
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.supercloud-logo {
  width: 50px;
  height: 50px;
}

.font-weight-700 {
  font-weight: 700;
}

.color-050505 {
  color: #050505;
}

.text-2xl {
  font-size: 2em;
  /* adjust as needed */
}

.font-semibold {
  font-weight: 600;
  /* adjust as needed */
}

/* Added styles for table cells to prevent text wrap and manage overflow */
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-column {
  max-width: 300px; /* Adjust as needed */
}

/* CSS */
.no-text-trim {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}
</style>
