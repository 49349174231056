<template>
  <div
    id="popup-modal"
    tabindex="-1"
    class="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-first-color rounded-lg shadow dark:bg-tenth-color">
        <button
          type="button"
          class="absolute top-3 end-2.5 text-tenth-color bg-transparent hover:bg-third-color hover:text-tenth-color rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:text-first-color dark:hover:bg-tenth-color dark:hover:text-first-color"
          @click="$emit('cancel')"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="p-4 md:p-5 text-center">
          <svg
            class="mx-auto mb-4 text-tenth-color w-12 h-12 dark:text-first-color"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
            />
          </svg>
          <h3 class="mb-5 text-lg font-normal text-first-color dark:text-first-color">
            Are you sure you want to connect this integration? You will be
            redireted outside of the app to complete the connection.
          </h3>
          <button
            @click="$emit('confirm')"
            type="button"
            class="btn"
          >
            Connect
          </button>
          <button
            @click="$emit('cancel')"
            type="button"
            class="btn"
          >
            No, cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
