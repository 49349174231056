export default {
  constructPayload(decodedToken) {
    return {
      headers: {
        "access-token": decodedToken["access-token"],
        client: decodedToken.client,
        uid: decodedToken.uid,
      },
      data: {
        data: {
          id: decodedToken.id,
          free_trial: decodedToken.trial,
          subscribed: decodedToken.subscribed,
          firstName: decodedToken.first_name,
          lastName: decodedToken.last_name,
          email: decodedToken.email,
        },
      },
    };
  },

  setTokens(response) {
    localStorage.setItem('access-token', response.headers['access-token']);
    localStorage.setItem('client', response.headers['client']);
    localStorage.setItem('uid', response.headers['uid']);
    localStorage.setItem('id', response.data.data.id);
    localStorage.setItem('trial', response.data.data.free_trial);
    localStorage.setItem('subscribed', response.data.data.subscribed);
    localStorage.setItem('firstName', response.data.data.firstName);
    localStorage.setItem('lastName', response.data.data.lastName);
    localStorage.setItem('email', response.data.data.email);
  },

  getTokens() {
    return {
      'access-token': localStorage.getItem('access-token'),
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid'),
      'id': localStorage.getItem('id'),
      'trial': localStorage.getItem('trial'),
      'subscribed': localStorage.getItem('subscribed'),
      'firstName': localStorage.getItem('firstName'),
      'lastName': localStorage.getItem('lastName'),
      'email': localStorage.getItem('email'),
    };
  },
  clearTokens() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    localStorage.removeItem('id');
    localStorage.removeItem('trial');
    localStorage.removeItem('subscribed');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
  },
};
