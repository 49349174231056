<template>
  <div class="flex items-center justify-end bg-white">
    <div
      v-if="selectedFilter === 'all-deletion' && !hasItemsAvailableForDelete"
      class="text-tenth-color"
    >
      <div
        id="alert-4"
        class="flex items-center p-4 mb-4 text-tenth-color rounded-lg bg-bg-first-color dark:bg-first-color dark:text-tenth-color"
        role="alert"
      >
        <svg
          class="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div class="ms-3 text-sm font-medium">
          No available items for deletion
        </div>
      </div>
    </div>
    <div
      v-else-if="
        selectedFilter === 'all-creation' && !hasItemsAvailableForCreate
      "
      class="text-tenth-color"
    >
      <div
        id="alert-4"
        class="flex items-center p-4 mb-4 text-tenth-color rounded-lg bg-bg-first-color dark:bg-first-color dark:text-tenth-color"
        role="alert"
      >
        <svg
          class="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div class="ms-3 text-sm font-medium">
          No available items for creation
        </div>
      </div>
    </div>

    <button
      v-if="selectedFilter === 'all-creation' && hasItemsAvailableForCreate"
      @click="handleMassAction"
      class="text-first-color focus:ring-4 focus:outline-none focus:ring-tenth-color dark:focus:ring-tenth-color font-medium rounded-lg text-sm px-5 py-2 text-center me-2 mb-2"
      :style="{ backgroundColor: buttonColor }"
    >
      Bulk creation
    </button>
    <button
      v-if="selectedFilter === 'all-deletion' && hasItemsAvailableForDelete"
      @click="handleMassAction"
      class="text-first-color focus:ring-4 focus:outline-none focus:ring-tenth-color dark:focus:ring-tenth-color font-medium rounded-lg text-sm px-5 py-2 text-center me-2 mb-2"
      :style="{ backgroundColor: buttonColor }"
    >
      Bulk deletion
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selectedFilter: {
      type: String,
      required: true,
    },
    hasItemsAvailableForDelete: {
      type: Boolean,
      required: true,
    },
    hasItemsAvailableForCreate: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonColor() {
      return this.selectedFilter === "all-deletion" ? "text-red-color" : "text-tenth-color";
    },
  },
  methods: {
    handleMassAction() {
      // Emit an event to the parent component to handle mass action
      this.$emit("mass-action");
    },
  },
};
</script>

<style scoped>
button {
  cursor: pointer;
  transition: background-color 0.2s;
}
</style>
