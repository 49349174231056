<template>
  <div class="flex flex-col p-6 text-tenth-color shadow-md sm:rounded-lg border border-e-0 border-second-color h-full">
    <div>
      <div class="flex items-center mb-4">
        <img src="/img/icons/black_logo.png" alt="Logo" class="w-8 h-8 mr-3" />
        <h2 class="bold-text text-2xl font-semibold">{{ subscriptionName }} plan</h2>
      </div>
      <p class="text-tenth-color mb-3 text-sm leading-relaxed">
        To switch plans, simply cancel your current subscription. Once it ends, you can subscribe to a new plan. Your
        data will be safe, and we'll automatically direct you to the pricing page when your current plan expires.
      </p>
      <p v-if="!subscriptionEndDate" class="bold-text font-semibold pt-3 pb-3 text-sm">
        You don't have any subscription yet.
      </p>
      <p v-else class="bold-text font-semibold pt-3 pb-3 text-sm mb-1">
        Next payment occurs on {{ subscriptionEndDate }}.
      </p>
    </div>
    <!-- Reduce top margin on this div -->
    <div class="flex justify-center space-x-4 mt-2">
      <button class="btn-billing"
        @click="cancelSubscription">
        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zM4 5a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H5a1 1 0 01-1-1V5zm3 4a1 1 0 000 2h6a1 1 0 000-2H7z"
            clip-rule="evenodd" />
        </svg>
        Cancel Subscription
      </button>
    </div>
  </div>
</template>

<script>
import stripe from "@/services/stripe";

export default {
  props: ['subscriptionName', 'subscriptionEndDate'],

  methods: {
    async cancelSubscription() {
      try {
        const response = await stripe.billingPortal();
        window.location.href = response.data.url;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.bold-text {
  color: #050505;
  /* Changed color */
  font-weight: 700;
}
</style>