import axios from "axios";
import jsyaml from "js-yaml";
import apiClient from './apiClient';

const API_URL = process.env.VUE_APP_API_URL;

export default {
  async index(query = '', page = 1, minimal = false) {
    try {
      const response = await apiClient.get(`api/v1/platform_users/platform_user_integrations`, {
        params: {
          page: page,
          query: query,
          minimal: minimal
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async show(id, minimal = false) {
    try {
      const response = await apiClient.get(
        `api/v1/platform_users/platform_user_integrations/${id}?minimal=${minimal}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async create(formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});
      const createResponse = await apiClient.post(
        `api/v1/platform_users/platform_user_integrations`,
        { platform_user_integration: translatedFormData });

      return createResponse;
    } catch (error) {
      throw error;
    }
  },

  async update(id, formData) {
    try {
      const response = await axios.get("/translations.yml");
      const translations = jsyaml.load(response.data);
      const payload = translations.payload;

      const translatedFormData = Object.keys(formData).reduce((result, key) => {
        const newKey = payload[key];
        result[newKey] = formData[key];
        return result;
      }, {});

      const updateResponse = await apiClient.put(
        `api/v1/platform_users/platform_user_integrations/${id}`,
        { platform_user_integration: translatedFormData });

      return updateResponse;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await apiClient.delete(
        `api/v1/platform_users/platform_user_integrations/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
